import { NotificationAudience } from '@/features/notifications/types'
import { getFullName } from '@/utils/common'
import { formatDateNew } from '@/utils/dateTime2'
import { GridValueGetter } from '@mui/x-data-grid'
import _ from 'lodash'

type ValueGetter<Row extends any> = (...args: Parameters<GridValueGetter<Row>>) => unknown

const VALUE_GETTERS_MAP: Record<string, ValueGetter<unknown>> = {
	date: (...[value, row]: Parameters<GridValueGetter<Date | null>>): Date => (value ? new Date(value) : new Date()),

	datePresent: (...[value, row]) => (value ? formatDateNew(value, 'dateMed') : 'Present'),

	fullName: (...[value, row]: Parameters<GridValueGetter<any>>) => getFullName(row),

	fullNameFromUser: (...[value, row]: Parameters<GridValueGetter<any>>) => getFullName(row.user),

	stripHtml: (...[value, row]) => (value as string)?.replace(/<[^>]*>?/gm, '') ?? '',

	stripNan: (...[value, row]) => (value === 'NaN' ? '' : value),

	audience: (...[value, row]: Parameters<GridValueGetter<{ audienceId: NotificationAudience }>>) => {
		return _.capitalize(Object.keys(NotificationAudience).find((key) => NotificationAudience[key] === row?.audienceId) || '')
	},

	capitalize: (...[value, row]) => _.capitalize(value),
} as const

export default VALUE_GETTERS_MAP
