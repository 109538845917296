import { Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useEffect, useState } from 'react'

interface ImageLoaderProps {
	src: string
	alt?: string
	className?: string
}

const FallBackText = styled('div')({
	color: 'gray',
	fontSize: '14px',
	textAlign: 'center',
	padding: '2.4rem',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
})

const ImageLoader = ({ src, alt = '', className }: ImageLoaderProps) => {
	const [hasError, setHasError] = useState(false)

	useEffect(() => {
		setHasError(false)
	}, [src])

	const handleError = () => {
		setHasError(true)
	}

	if (!src) {
		return null
	}

	return hasError ? (
		<FallBackText>
			<Typography variant="h5">The image you're attempting to view has been removed or can no longer be accessed.</Typography>
		</FallBackText>
	) : (
		<img src={src} alt={alt} className={className} onError={handleError} />
	)
}

export default ImageLoader
