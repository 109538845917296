import { Id } from '@/lib/types'

const ROOT = 'home' as const

const HOME = {
	ROOT,
	POST_INFO: (id?: Id) => `${ROOT}/post/${id !== undefined ? id : ':id'}`,
} as const

export default HOME
