import { makeStyles } from '@material-ui/core/styles'
import { PlusCircle, XCircle } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'
import * as yup from 'yup'

import { FormProps } from '@/components/Form/Form'
import { FieldKind } from '@/components/Form/types'
import AdminModal from '@/components/Modal/AdminModal'
import { ModalKind } from '@/components/Modal/types'
import { SelectOption } from '@/components/Selects/types'
import { SCHOOL_ID } from '@/constants/configuration'
import { ICON_SIZES } from '@/constants/iconSizes'
import {
	useDeleteScheduledNotificationsMutation,
	useGetScheduledNotificationsQuery,
	usePatchScheduledNotificationsMutation,
	usePostScheduledNotificationsMutation,
} from '@/features/notifications/api'
import { NotificationAudience } from '@/features/notifications/types'
import { useAppSelector } from '@/store'
import { selectAuth } from '@/store/auth'
import { getEnumKeys } from '@/utils/enums'
import useAdminModal from '../../../components/Modal/useAdminModal'
import { useGetNotificationMetaQuery } from '../api/notificationsApi'
import { CommonCard } from '../components/CommonCard'
import { DataTableWithRoleCheck } from '../components/dataTable/DataTable'
import { Layout } from '../components/Layout'
import { inflectString } from '../utils/getTypeLabel'

const useStyles = makeStyles((theme) => ({
	cardWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		[theme.breakpoints.up('md')]: {
			maxWidth: '88vw',
		},
	},
}))

const NotificationsMainPage = () => {
	const classes = useStyles()
	const userId = useAppSelector(selectAuth).userId

	const [createMutation] = usePostScheduledNotificationsMutation()
	const formProps: Omit<FormProps, 'setModalState'> = useMemo(
		() => ({
			createMutation,
			title: 'Schedule Notification',
			fields: [
				{
					label: 'Heading',
					name: 'heading',
					placeholder: 'What would you like to share?',
					isRequired: true,
					kind: FieldKind.INPUT,
					customValidation: yup.string().max(50, "Content can't exceed 50 characters"),
				},
				{
					label: 'Content',
					name: 'content',
					placeholder: 'Draft a message...',
					isRequired: true,
					kind: FieldKind.INPUT,
					customValidation: yup.string().max(150, "Content can't exceed 150 characters"),
				},
				{
					label: 'Audience',
					name: 'audienceId',
					kind: FieldKind.SELECT,
					isRequired: true,
					multiple: false,
					placeholder: 'Who are you notifying?',
					options: getEnumKeys(NotificationAudience).map(
						(option): SelectOption => ({
							label: inflectString({ subj: String(option), count: 1, options: ['capitalize'] }),
							value: NotificationAudience[option],
							disabled: false,
						}),
					),
				},
				{
					label: 'Date and Time',
					name: 'scheduledAt',
					isRequired: true,
					kind: FieldKind.DATE_TIME,
				},
				{
					label: 'Launch URL',
					name: 'launchUrl',
					isRequired: false,
					placeholder: 'Optional: Add a url to open when users click this notification, instead of opening the home page.',
					customValidation: yup.string().max(1000, "Content can't exceed 1000 characters").url(),
					kind: FieldKind.INPUT,
				},
			],
			additionalSubmissionContext: {
				userId,
				schoolId: SCHOOL_ID,
			},
		}),
		[createMutation, userId],
	)

	const modalConfig = useAdminModal({
		modalKinds: [ModalKind.FORM],
		formProps,
	})

	const [deleteNotifications] = useDeleteScheduledNotificationsMutation()
	const [selectedRows, setSelectedRows] = useState<string[]>([])

	const handleDeleteEntities = useCallback(() => {
		deleteNotifications({
			notificationIds: selectedRows,
		})
	}, [deleteNotifications, selectedRows])

	const tableActions = useMemo(
		() => [
			{
				label: 'Remove',
				icon: <XCircle size={ICON_SIZES.sm} weight="bold" />,
				onClick: handleDeleteEntities,
				isVisible: selectedRows.length > 0,
			},
			{
				label: 'Create',
				icon: <PlusCircle size={ICON_SIZES.sm} weight="bold" />,
				onClick: () => modalConfig.setModalState({ isOpen: true, modalKind: ModalKind.FORM }),
				isVisible: true,
			},
		],
		[handleDeleteEntities, modalConfig, selectedRows],
	)

	return (
		<Layout>
			<div className={classes.cardWrapper}>
				<CommonCard title="Scheduled Notifications" subHeader="Create and manage scheduled notifications">
					<DataTableWithRoleCheck
						listQuery={useGetScheduledNotificationsQuery}
						metaQuery={useGetNotificationMetaQuery}
						mutationDefinition={usePatchScheduledNotificationsMutation}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						actions={tableActions}
					/>
				</CommonCard>
			</div>
			<AdminModal {...modalConfig} />
		</Layout>
	)
}

export default NotificationsMainPage
