const ROOT = 'network' as const

const NETWORK = {
	ROOT,
	MY: `${ROOT}/my`,
	SEARCH: `${ROOT}/search`,
	USER: (id?: string) => `${ROOT}/user/${id !== undefined ? id : ':id'}`,
} as const

export default NETWORK
