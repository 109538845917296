import { Typography } from '@material-ui/core'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useState } from 'react'

import { ImageViewModal, TextButton } from '@/components'
import { STYLES } from '@/constants'

interface StylesProps {
	url?: string
	aspect?: number
	placeholderSize?: number
	canEdit?: boolean
	borderRadius?: number
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ url, canEdit, aspect = 1, borderRadius }) => ({
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		paddingTop: `${100 / aspect}%`,
		position: 'relative',
		cursor: url && canEdit ? 'pointer' : 'inherit',
		borderRadius,
	}),
	imageContainer: ({ url, borderRadius }) => ({
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: url
			? theme.palette.common.black
			: theme.palette.type === 'dark'
			? theme.palette.background.default
			: theme.palette.grey[200],
		borderRadius,
	}),
	image: ({ url, borderRadius }) => ({
		position: 'relative',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		justifyContent: 'flex-start',
		background: `url(${url}) center center / contain no-repeat`,
		borderRadius,
	}),
	placeholder: ({ borderRadius }) => ({
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'calc(100% - 30px)',
		padding: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius,
	}),
	placeholderText: ({ placeholderSize }) => ({
		color: theme.palette.text.secondary,
		textTransform: 'uppercase',
		fontWeight: 700,
		fontSize: `${placeholderSize}em`,
	}),
	buttonsContainer: ({ borderRadius }) => ({
		padding: 15,
		background: `linear-gradient(0deg, rgba(174,174,174,0) 0%, rgba(0,0,0,0.6) 90%)`,
		position: 'absolute',
		width: 'calc(100% - 30px)',
		height: 'calc(100% - 30px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		justifyContent: 'flex-start',
		borderRadius,
	}),
}))

interface GroupImageProps extends StylesProps {
	onEdit?: () => void
	className?: string
	groupName?: string
	openImageByClick?: boolean
}

const GroupImage = ({
	url,
	onEdit,
	canEdit,
	aspect = 1,
	className,
	groupName = 'name',
	placeholderSize = 1,
	borderRadius,
	openImageByClick = false,
}: GroupImageProps) => {
	const classes = useStyles({ url, aspect, placeholderSize, borderRadius })
	const appTheme = useTheme()
	const [fullImageView, setFullImageView] = useState('')

	const handleOpenImageView = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!url || !openImageByClick) return
		e.stopPropagation()
		e.preventDefault()
		setFullImageView(url ?? '')
	}

	const handleCloseImageView = () => setFullImageView('')

	const handleEditGroupImage = (e: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		e.stopPropagation()
		e.preventDefault()
		if (onEdit) onEdit()
	}

	return (
		<div className={classNames(classes.root, className)} onClick={handleOpenImageView}>
			<div className={classes.imageContainer}>
				<div className={classes.image}>
					<ImageViewModal src={fullImageView} isOpen={!!fullImageView} onClose={handleCloseImageView} />
					{!url && (
						<div className={classes.placeholder}>
							<Typography className={classes.placeholderText}>{groupName.slice(0, 2)}</Typography>
						</div>
					)}
					<div className={classes.buttonsContainer}>
						{canEdit && (
							<TextButton onClick={handleEditGroupImage} color={appTheme.palette.common.white}>
								Change Photo
							</TextButton>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default GroupImage
