import { ICON_SIZES } from '@/constants/iconSizes'
import { useTheme } from '@material-ui/core'
import {
	ArrowCircleRight,
	Bell,
	Books,
	Briefcase,
	CalendarBlank,
	CaretRight,
	ChatCentered,
	ChatsCircle,
	Check,
	CheckCircle,
	CheckSquare,
	Circle,
	Copy,
	Crown,
	Envelope,
	Eye,
	FacebookLogo,
	GlobeHemisphereWest,
	GraduationCap,
	HandHeart,
	Heart,
	House,
	IconProps,
	InstagramLogo,
	Link,
	LinkedinLogo,
	ListChecks,
	ListMagnifyingGlass,
	MapPin,
	Plus,
	Question,
	Repeat,
	ShareFat,
	ShootingStar,
	Slideshow,
	Sparkle,
	Square,
	Trash,
	TwitterLogo,
	UsersThree,
	X,
	XCircle,
	YoutubeLogo,
} from '@phosphor-icons/react'
import { FC, useCallback } from 'react'
import { ValueOf } from 'type-fest'

const ICONS_MAP = {
	arrowCircleRight: ArrowCircleRight,
	book: Books,
	checkSquare: CheckSquare,
	caretRight: CaretRight,
	circle: Circle,
	checkCircle: CheckCircle,
	chatsCircle: ChatsCircle,
	comment: ChatCentered,
	copy: Copy,
	check: Check,
	crown: Crown,
	email: Envelope,
	events: CalendarBlank,
	eye: Eye,
	forms: ListChecks,
	graduation: GraduationCap,
	group_chats: ChatsCircle,
	bell: Bell,
	heart: Heart,
	house: House,
	interests: Sparkle,
	jobs: Briefcase,
	link: Link,
	location: MapPin,
	organizations: UsersThree,
	outOfClass: ShootingStar,
	position: Briefcase,
	trash: Trash,
	research: ListMagnifyingGlass,
	plus: Plus,
	repeat: Repeat,
	shareFat: ShareFat,
	slideShow: Slideshow,
	socialMediaFacebook: FacebookLogo,
	socialMediaTwitter: TwitterLogo,
	socialMediaInstagram: InstagramLogo,
	socialMediaLinkedIn: LinkedinLogo,
	socialMediaYoutube: YoutubeLogo,
	square: Square,
	xCircle: XCircle,
	x: X,
	study_abroad: GlobeHemisphereWest,
	volunteers: HandHeart,
	question: Question,
} as const

export type TIconName = keyof typeof ICONS_MAP

export enum IconButtonVariant {
	SOLID = 'solid',
	OUTLINED = 'outlined',
	NO_BG = 'noBackground',
}

export interface IconWrapperProps extends Omit<IconProps, 'size'> {
	iconKey: TIconName
	disabled?: boolean
	isHovered?: boolean
	variant?: IconButtonVariant
	size?: ValueOf<typeof ICON_SIZES>
}

export const IconWrapper: FC<IconWrapperProps> = ({
	iconKey,
	size: propSize,
	weight = 'regular',
	color: propColor,
	variant = IconButtonVariant.SOLID,
	disabled = false,
	isHovered = false,
	...componentProps
}) => {
	const Component = ICONS_MAP[iconKey]
	const theme = useTheme()

	const getColor = useCallback(
		(variant, isDisabled, isHovered) => {
			const COLOR_MAP = {
				[IconButtonVariant.OUTLINED]: {
					disabled: theme.palette.primary.light,
					default: theme.palette.primary.main,
					hovered: theme.palette.primary.dark,
				},
				[IconButtonVariant.SOLID]: {
					disabled: theme.palette.common.white,
					default: theme.palette.common.white,
					hovered: theme.palette.primary.main,
				},
				[IconButtonVariant.NO_BG]: {
					disabled: theme.palette.primary.light,
					default: theme.palette.primary.main,
					hovered: theme.palette.primary.dark,
				},
			}
			const buttonState = isDisabled ? 'disabled' : isHovered ? 'hovered' : 'default'
			return COLOR_MAP[variant][buttonState]
		},
		[theme],
	)

	if (!Component) {
		return null
	}

	const color = propColor || getColor(variant, disabled, isHovered)
	const size = propSize || ICON_SIZES.md

	return <Component weight={weight} size={size} color={color} {...componentProps} />
}

export default IconWrapper
