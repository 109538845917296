import { IconWrapper, ImageViewModal } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'
import { useTheme } from '@material-ui/core'
import { Typography } from '@mui/material'
import { styled } from '@mui/styles'
import React, { useState } from 'react'

const StyledCell = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: '100%',
	cursor: 'ne-resize',
	// on hover underline helper text
	transition: 'text-decoration 0.3s ease',
	'&:hover > .helper-text': {
		textDecoration: 'underline',
	},
})

type ImageTemplateProps = { photoUrl: string } & object
const ImageTemplate: React.FC<ImageTemplateProps> = ({ photoUrl }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const theme = useTheme()

	if (!photoUrl) {
		return null
	}

	const toggleModal = (e: React.SyntheticEvent<HTMLElement>) => {
		e.preventDefault()
		setIsModalOpen((state) => !state)
	}

	return (
		<>
			<ImageViewModal src={photoUrl} isOpen={isModalOpen} onClose={toggleModal} />
			<StyledCell onClick={toggleModal}>
				<Typography variant="body2" className="helper-text">
					Show
				</Typography>
				<IconWrapper iconKey="slideShow" size={ICON_SIZES.md} color={theme.palette.text.secondary} />
			</StyledCell>
		</>
	)
}

export default ImageTemplate
