const ROOT = 'settings' as const

const SETTINGS = {
	ROOT,
	BLOCKED_ACCOUNTS: `${ROOT}/privacy/blocked-accounts`,
	MUTED_ACCOUNTS: `${ROOT}/privacy/muted-accounts`,
	PROFILE: `${ROOT}/privacy/profile`,
	NOTIFICATIONS: `${ROOT}/notifications`,
} as const

export default SETTINGS
