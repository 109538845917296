import { FC, useCallback, useMemo, useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import { UserOrganizationTypes } from '@/anthology/organizations/types/UserOrganizationTypes'
import { SubmissionStatusModal } from '@/anthology/components/ModalWithSubmittedPopup'
import useCurrentUserOrgs from '@/anthology/organizations/hooks/useCurrentUserOrgs'
import { Modal } from '@/components'
import { AVAILABLE_EVENT_RESPONSES } from '../../constants'
import useCreateUpdateRsvpMutation, { FormData } from '../../hooks/useCreateUpdateRsvpMutation'
import EventRsvpOption from '../../types/EventRsvpOption'
import DtoEvent from '../../types/dto/DtoEvent'
import { IQuestionsFormProps } from './QuestionsForm'
import RsvpButton from './RsvpButton'
import RsvpModal from './RsvpModal'
import { generateDynamicLabel } from '@/features/adminConsole/utils/getTypeLabel'

export type IEventRsvpProps = {
	eventId: DtoEvent['id']
	settings: DtoEvent['rsvpSettings']
	response: DtoEvent['response']
	pastEvent?: boolean
}

const useStyles = makeStyles((theme) => ({
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
	rsvpSettings: {
		padding: 5,
		marginBottom: 15,
		backgroundColor: theme.palette.primary.light,
	},
	spotsAvailable: {
		fontWeight: 700,
	},
	noSpotsAvailable: {
		paddingTop: 5,
	},
	rsvpDiv: {
		width: '100%',
		flex: 1,
	},
}))

type TRsvpState = {
	isRsvpModalOpen: boolean
	userResponse?: EventRsvpOption
	isSubmissionModalOpen: boolean
}

const DEFAULT_STATE: TRsvpState = {
	isRsvpModalOpen: false,
	userResponse: null,
	isSubmissionModalOpen: false,
}

const EventRsvp: FC<IEventRsvpProps> = ({ settings, eventId, response: currentResponse, pastEvent }) => {
	const classes = useStyles()

	const [rsvpState, setRsvpState] = useState<TRsvpState>({
		...DEFAULT_STATE,
		userResponse: currentResponse?.response,
	})
	const {
		handleSubmitRsvpForm,
		query: { isError, isLoading, isSuccess, reset },
	} = useCreateUpdateRsvpMutation({ eventId, response: currentResponse })

	const { isLoading: isOrganizationCheckLoading, data: orgMembershipData } = useCurrentUserOrgs(
		UserOrganizationTypes.member,
		!settings?.organizationRepresentationEnabled,
		1,
	)

	const showOrganizationError = orgMembershipData?.items.length === 0 && settings?.organizationRepresentationRequired

	const shouldOpenRsvpModal = useCallback(
		(response: EventRsvpOption) => {
			return response === 'No'
				? false
				: !!settings.questions || (settings.organizationRepresentationEnabled && !!orgMembershipData?.items.length)
		},
		[orgMembershipData?.items.length, settings.organizationRepresentationEnabled, settings.questions],
	)

	const handleOpenRsvpModal = useCallback(
		(response: EventRsvpOption) => () => {
			const newState: Partial<TRsvpState> = {
				userResponse: response,
			}

			newState.isSubmissionModalOpen = showOrganizationError

			if (response !== currentResponse?.response && !showOrganizationError) {
				if (shouldOpenRsvpModal(response)) {
					newState.isRsvpModalOpen = true
				} else {
					newState.isSubmissionModalOpen = true

					handleSubmitRsvpForm({
						response,
					})
				}
			}
			setRsvpState((prev) => ({
				...prev,
				...newState,
			}))
		},
		[currentResponse?.response, handleSubmitRsvpForm, shouldOpenRsvpModal, showOrganizationError],
	)

	const onSubmit: IQuestionsFormProps['onSubmit'] = useCallback(
		({ data, organizationIds }) => {
			setRsvpState((prev) => ({
				...prev,
				isSubmissionModalOpen: true,
				isRsvpModalOpen: false,
			}))
			const formData: FormData = {
				response: rsvpState.userResponse,
				organizationIds,
			}

			if (rsvpState.userResponse === 'Yes' && data?.formData) {
				formData.answers = JSON.stringify(data.formData)
			}

			handleSubmitRsvpForm(formData)
		},
		[handleSubmitRsvpForm, rsvpState.userResponse],
	)

	const closeModal = useCallback(() => {
		reset()
		setRsvpState({ ...DEFAULT_STATE })
	}, [reset])

	const getActiveButtonStyle = useCallback(
		(res: EventRsvpOption) => (res === currentResponse?.response ? classes.menuActiveButton : ''),
		[classes.menuActiveButton, currentResponse?.response],
	)

	const getButtonLabel = (currentResponse: any) => {
		// If the user is waitlisted, show "Wait Listed"
		if (currentResponse?.waitListedOn !== null && currentResponse?.response === 'Yes') {
			return 'Waitlisted'
		}
		// If the user has responded, show the response label
		if (currentResponse?.response) {
			return AVAILABLE_EVENT_RESPONSES[currentResponse.response]
		}
		// Default to "RSVP"
		return 'RSVP'
	}

	const getRsvpLabel = (res: EventRsvpOption, currentResponse: any, settings: any) => {
		// If the user is not going and no spots are available, show "Join Waitlist" instead of "Going"
		if (currentResponse?.response !== 'Yes' && res === 'Yes' && settings.spotsAvailable === 0) {
			return 'Join Waitlist'
		}
		// Otherwise, show the regular RSVP option
		return AVAILABLE_EVENT_RESPONSES[res]
	}

	const rsvpButtons = useMemo(
		() =>
			(Object.keys(AVAILABLE_EVENT_RESPONSES) as EventRsvpOption[]).map((res) => ({
				label: getRsvpLabel(res, currentResponse, settings),
				onClick: handleOpenRsvpModal(res),
				className: getActiveButtonStyle(res),
			})),
		[getActiveButtonStyle, handleOpenRsvpModal, currentResponse, settings],
	)

	const buttonLabel = useMemo(() => getButtonLabel(currentResponse), [currentResponse])

	const { shouldShowRemainingRsvps } = settings
	const noSpotsAvailable = settings.spotsAvailable === 0
	const isOnWaitlist = currentResponse?.response === 'Yes' && currentResponse?.waitListedOn !== null

	return (
		<>
			{!showOrganizationError && (
				<RsvpModal
					hasMemberships={!!orgMembershipData?.items.length}
					settings={settings}
					isOpen={rsvpState.isRsvpModalOpen}
					response={currentResponse}
					onClose={closeModal}
					onSubmit={onSubmit}
				/>
			)}

			<Modal overflow="inherit" isOpen={rsvpState.isSubmissionModalOpen}>
				<SubmissionStatusModal
					handleClose={closeModal}
					isError={isError || showOrganizationError}
					isLoading={(isLoading || isOrganizationCheckLoading) && !showOrganizationError}
					isSuccess={isSuccess}
					submittedMessage="Your RSVP has been submitted"
					errorMessage={
						showOrganizationError
							? 'Membership Required: To RSVP for this event, you must be a member of the organization.'
							: 'Unable to submit RSVP. Please try again later.'
					}
				/>
			</Modal>
			<div className={classes.rsvpDiv}>
				{(shouldShowRemainingRsvps || noSpotsAvailable || isOnWaitlist) && (
					<div className={classes.rsvpSettings}>
						{/* If we're allowed to show remaining spots, and user is not on waitlist, show remaining spots */}
						{shouldShowRemainingRsvps && !isOnWaitlist && (
							<Typography className={classes.spotsAvailable}>
								{settings.spotsAvailable ? '' : 'No'}{' '}
								{generateDynamicLabel({ count: settings.spotsAvailable, subj: 'Spot', suffix: 'Available' })}
							</Typography>
						)}

						{/* If the user is not on the waitlist and there are no spots available, show message */}
						{noSpotsAvailable && !isOnWaitlist && (
							<Typography className={classes.noSpotsAvailable}>
								This event has limited attendance and it is currently full. Reserve your place on the waitlist to be automatically added if
								room becomes available.
							</Typography>
						)}

						{/* If user is on the waitlist, show them that they're on the waitlist */}
						{isOnWaitlist && (
							<Typography className={classes.noSpotsAvailable}>
								You are on the waitlist. You will be automatically added if room becomes available.
							</Typography>
						)}
					</div>
				)}

				<RsvpButton
					rsvpButtons={rsvpButtons}
					buttonLabel={buttonLabel}
					isInviteOnly={settings.isInviteOnly}
					hasResponse={!!currentResponse?.id}
					pastEvent={pastEvent}
				/>
			</div>
		</>
	)
}

export default EventRsvp
