import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AirplaneTakeoff, Bell, ChartPie, GearSix, Info, List, Question, SignOut, Warning } from '@phosphor-icons/react'
import { isNumber } from 'lodash'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { IconWrapper } from '@/components'
import { CounterBadge } from '@/components/CounterBadge'
import ErrorBoundary from '@/components/error/ErrorBoundary'
import Footer from '@/components/Footer'
import { Header } from '@/components/Header'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import MenuPopover from '@/components/MenuPopover'
import MessagesPopper from '@/components/Messages/MessagesPopper'
import NavigationTab from '@/components/NavigationTab'
import NavigationTabs from '@/components/NavigationTabs'
import NotificationsPopper from '@/components/Notifications/NotificationsPopper'
import Notifier from '@/components/Notifier'
import { PATHS, STYLES } from '@/constants'
import { isEngage } from '@/constants/configuration'
import { ICON_SIZES } from '@/constants/iconSizes'
import { HeaderInfo } from '@/features/adminConsole/components/HeaderInfo'
import { ADMIN_ROOT_PATH, Route } from '@/features/adminConsole/routes/config'
import { AnalyticsRole } from '@/features/adminConsole/types/AnalyticsRole'
import { useGetUserNotificationsQuery } from '@/features/notifications/api'
import { onboardingSlice } from '@/features/onboarding/slice'
import useUrlIncludesPaths from '@/hooks/useIncludesUrl'
import { useMainNavigation } from '@/hooks/useMainNavigation'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { getFeatures } from '@/lib/splitio'
import { useAppDispatch } from '@/store'
import { showExitConfirmationModal } from '@/store/app'
import { deleteAccountRequest, getUserInfo, signOutRequest } from '@/store/auth'
import { getNumberOfUnViewed } from '@/store/messages'
import { isDevEnv } from '@/utils/authHandlers'
import { isValidUrl } from '@/utils/common'
import { openAboutAppPage, submitFeedback } from '@/utils/services'
import { includeUrl } from '@/utils/urlHandlers'
import { push } from 'connected-react-router'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: `${STYLES.MAIN_HEADER_HEIGHT}vh`,
		width: '100%',
		height: 'max-content',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		color: theme.palette.text.primary,
	},
	container: {
		minHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'row',
	},
	profileTabIcon: {
		width: 30,
		height: 30,
	},
	notificationsPopper: {
		marginTop: 5,
		padding: '10px 20px',
		minWidth: '10vw',
		boxShadow: 'none',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 8,
	},
	notificationsPopperTitle: {
		fontWeight: 700,
	},
	messagesIsOn: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	messageNotificationDot: {
		width: 10,
		height: 10,
		backgroundColor: theme.colors.red[500],
		border: `solid 1px ${theme.colors.white}`,
		borderRadius: '50%',
		position: 'absolute',
		top: 4,
		right: -8,
	},
}))

const tabsInfo = {
	home: {
		key: 'home-tab',
		index: 0,
		pathname: PATHS.APP.HOME,
	},
	message: {
		key: 'message-tab',
		index: 1,
		pathname: PATHS.APP.MESSAGES,
	},
	notification: {
		key: 'notification-tab',
		index: 2,
	},
	profile: {
		key: 'profile-tab',
		index: 4,
		pathname: PATHS.APP.PROFILE,
	},
	menu: {
		key: 'menu-tab',
		index: 5,
	},
} as { [k: string]: any }

const tabsIndexByPath = Object.keys(tabsInfo).reduce(
	(acc, key) => ({
		...acc,
		[tabsInfo[key].pathname]: tabsInfo[key].index,
	}),
	{} as any,
)

const hideFooterPaths = [PATHS.APP.HOME]

const getActiveNavigation = (path: string) => {
	const activeTab = tabsIndexByPath[path]

	return { activeTab, hideIndicator: !isNumber(activeTab) }
}

const MainLayout = ({ children, sidebar }: any) => {
	const isDev = isDevEnv()
	const theme = useTheme()
	const ALL_FEATURES = getFeatures({ iconProps: { fontSize: ICON_SIZES.sm } })
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { pathname } = useLocation()
	const hideFooter = useUrlIncludesPaths(hideFooterPaths)
	const matches = useMediaQuery('(max-width:960px)')

	const numberOfUnViewed = useSelector(getNumberOfUnViewed)

	const { id: userId, analyticsRole, externalId, photoUrl } = useSelector(getUserInfo)

	const { data: allNotificationsData } = useGetUserNotificationsQuery({ userId, type: 'all' })

	const [value, setValue] = useState(0)
	const [prevValue, setPrevValue] = useState(0)
	const [showSettingsMenu, setShowSettingsMenu] = useState(false)

	const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [messagesAnchorEl, setMessagesAnchorEl] = useState<HTMLButtonElement | null>(null)

	const getIconColor = useCallback(
		(tabIndex) => (tabIndex === value ? theme.palette.primary.main : theme.palette.text.primary),
		[theme, value],
	)

	const getIconWeight = useCallback((tabIndex) => (tabIndex === value ? 'fill' : 'regular'), [value])

	const [hideIndicator, setHideIndicator] = useState(false)

	const handleSavePrevActiveTab = useCallback(
		(newTab: number) => {
			const { activeTab } = getActiveNavigation(pathname)

			setPrevValue(activeTab)
			setValue(newTab)
		},
		[pathname],
	)

	const handleRestorePrevActiveTab = useCallback(() => {
		setValue(prevValue)
		setHideIndicator(false)
	}, [prevValue])

	const handleOpenMenu = useCallback(
		(newTab: number, event: React.MouseEvent<HTMLButtonElement>) => {
			handleSavePrevActiveTab(newTab)
			setHideIndicator(true)
			setMenuAnchorEl(event.currentTarget)
		},
		[handleSavePrevActiveTab],
	)

	const handleCloseMenu = useCallback(() => {
		setShowSettingsMenu(false)
		setMenuAnchorEl(null)
		handleRestorePrevActiveTab()
	}, [handleRestorePrevActiveTab])

	const changePathConfirmationHandler = useCallback(
		(redirectPath: string, onConfirm: () => void) => {
			const confirmationRequired = [
				PATHS.APP.EVENTS_CREATE,
				PATHS.APP.PATH_BUILDER,
				PATHS.APP.PATH_BUILDER_QUESTIONS,
				PATHS.APP.PATH_BUILDER_SUMMARY,
				PATHS.APP.PATH_BUILDER_GRANT,
				PATHS.APP.PATH_BUILDER_LAST_STEP,
				PATHS.APP.EVENTS_EDIT(),
			]
			if (includeUrl(confirmationRequired)) {
				dispatch(showExitConfirmationModal({ isOpen: true, redirectPath, onConfirm }))
			} else {
				onConfirm()
				dispatch(push(redirectPath))
			}
		},
		[dispatch, push],
	)

	const handleClickAbout = useCallback(() => {
		openAboutAppPage()
		handleCloseMenu()
	}, [handleCloseMenu])

	const handleClickFeedback = useCallback(() => {
		submitFeedback()
		handleCloseMenu()
	}, [handleCloseMenu])

	const settingsButtons = useMemo(
		() => [
			{
				icon: <Info />,
				label: 'Privacy',
				onClick: () => changePathConfirmationHandler(PATHS.APP.SETTINGS_BLOCKED_ACCOUNTS, handleCloseMenu),
			},
			{
				icon: <Bell />,
				label: 'Notifications',
				onClick: () => changePathConfirmationHandler(PATHS.APP.SETTINGS_NOTIFICATIONS, handleCloseMenu),
			},
			{
				icon: <Question />,
				label: 'Help & Feedback',
				onClick: handleClickFeedback,
			},
			...(isEngage()
				? []
				: [
						{
							icon: <Info />,
							label: 'About',
							onClick: handleClickAbout,
						},
				  ]),
		],
		[changePathConfirmationHandler, handleClickAbout, handleClickFeedback, handleCloseMenu],
	)

	const menuButtons = useMemo(
		() => [
			{
				icon: <GearSix fontSize={ICON_SIZES.sm} />,
				label: 'Settings',
				onClick: () => setShowSettingsMenu(true),
				showArrow: true,
			},
			{
				icon: <Question />,
				label: 'Help & Feedback',
				onClick: handleClickFeedback,
				showArrow: true,
			},
			...(isEngage()
				? []
				: [
						{
							icon: <Info />,
							label: 'About',
							onClick: handleClickAbout,
							showArrow: true,
						},
				  ]),
			{
				icon: <SignOut />,
				label: 'Sign Out',
				onClick: () => dispatch(signOutRequest()),
			},
			...(analyticsRole === AnalyticsRole.SYSTEM_ADMIN
				? [
						{
							icon: <AirplaneTakeoff />,
							label: 'Review Signup Process',
							onClick: () => {
								dispatch(onboardingSlice.actions.setIsModerating(true))
								dispatch(push(PATHS.SIGN_UP.STEPS))
							},
						},
				  ]
				: []),
			...(isDev
				? [
						{
							icon: <Warning />,
							label: 'Delete Account',
							onClick: () => dispatch(deleteAccountRequest()),
						},
				  ]
				: []),
		],
		[dispatch, handleClickAbout, handleClickFeedback, isDev],
	)
	const filteredFeatures = useMainNavigation(Object.values(ALL_FEATURES))
	const navButtons = useMemo(
		() =>
			filteredFeatures.map(({ icon, label, path }) => ({
				icon,
				label,
				onClick: () => (isValidUrl(path) ? window.open(path) : changePathConfirmationHandler(path, handleCloseMenu)),
			})),
		[changePathConfirmationHandler, filteredFeatures, handleCloseMenu],
	)

	const renderSideMenuButtons = useMemo(() => {
		if (showSettingsMenu) return settingsButtons
		if (matches) return [...navButtons, ...menuButtons]

		return menuButtons
	}, [matches, menuButtons, navButtons, settingsButtons, showSettingsMenu])

	const handleOpenNotificationPopover = useCallback(
		(newTab: number, event: React.MouseEvent<HTMLButtonElement>) => {
			handleSavePrevActiveTab(newTab)
			setNotificationsAnchorEl(event.currentTarget)
		},
		[handleSavePrevActiveTab],
	)

	const handleCloseNotificationPopover = useCallback(() => {
		setNotificationsAnchorEl(null)
		handleRestorePrevActiveTab()
	}, [handleRestorePrevActiveTab])

	const handleClickLogo = useCallback(() => {
		dispatch(push(PATHS.APP.HOME))
	}, [push])

	const handleToggleNotificationPopover = useCallback(
		(newTab: number, event: React.MouseEvent<HTMLButtonElement>) => {
			if (!notificationsAnchorEl) {
				handleOpenNotificationPopover(newTab, event)
			} else {
				handleCloseNotificationPopover()
			}
		},
		[handleCloseNotificationPopover, handleOpenNotificationPopover, notificationsAnchorEl],
	)

	const handleToggleMenuPopover = useCallback(
		(newTab: number, event: React.MouseEvent<HTMLButtonElement>) => {
			if (!menuAnchorEl) {
				handleOpenMenu(newTab, event)
			} else {
				handleCloseMenu()
			}
		},
		[handleCloseMenu, handleOpenMenu, menuAnchorEl],
	)

	const handleOpenMessagesPopover = useCallback(
		(newTab: number, event: React.MouseEvent<HTMLButtonElement>) => {
			handleSavePrevActiveTab(newTab)
			setMessagesAnchorEl(event.currentTarget)
		},
		[handleSavePrevActiveTab],
	)

	const handleCloseMessagesPopover = useCallback(() => {
		setMessagesAnchorEl(null)
		handleRestorePrevActiveTab()
	}, [handleRestorePrevActiveTab])

	const handleToggleMessagesPopover = useCallback(
		(newTab: number, event: React.MouseEvent<HTMLButtonElement>) => {
			if (pathname.includes(PATHS.APP.MESSAGES)) return

			if (!messagesAnchorEl) {
				handleOpenMessagesPopover(newTab, event)
			} else {
				handleCloseMessagesPopover()
			}
		},
		[handleCloseMessagesPopover, handleOpenMessagesPopover, pathname, messagesAnchorEl],
	)

	useLayoutEffect(() => {
		// set initial tab
		const { activeTab, hideIndicator } = getActiveNavigation(pathname)

		setValue(activeTab)
		setHideIndicator(hideIndicator)
	}, [pathname])

	const tabs = useMemo(
		() => [
			<NavigationTab
				{...tabsInfo.home}
				icon={<IconWrapper iconKey="house" color={getIconColor(0)} weight={getIconWeight(0)} />}
				onClick={(tabIndex) =>
					changePathConfirmationHandler(PATHS.APP.HOME, () => {
						setValue(tabIndex)
						setHideIndicator(false)
					})
				}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
			<NavigationTab
				{...tabsInfo.message}
				icon={
					<div className={classes.messagesIsOn}>
						<CounterBadge badgeContent={numberOfUnViewed}>
							<IconWrapper iconKey="chatsCircle" color={getIconColor(1)} weight={getIconWeight(1)} />
						</CounterBadge>
					</div>
				}
				onClick={handleToggleMessagesPopover}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
			<NavigationTab
				{...tabsInfo.notification}
				icon={
					<CounterBadge badgeContent={allNotificationsData ? allNotificationsData.unviewed : 0}>
						<IconWrapper iconKey="bell" color={getIconColor(2)} weight={getIconWeight(2)} />
					</CounterBadge>
				}
				onClick={handleToggleNotificationPopover}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
			...(!analyticsRole
				? []
				: [
						<NavigationTab
							{...tabsInfo.admin}
							icon={<ChartPie fontSize={ICON_SIZES.md} />}
							onClick={(tabIndex) =>
								changePathConfirmationHandler(`${PATHS.ADMIN}/${Route.Home}`, () => {
									setValue(tabIndex)
									setHideIndicator(false)
								})
							}
							activeTabIndex={value}
							hideIndicator={false}
							centered={true}
						/>,
				  ]),
			<NavigationTab
				{...tabsInfo.profile}
				icon={<LazyLoadProfileImage className={classes.profileTabIcon} externalId={externalId} photoUrl={photoUrl} />}
				onClick={(tabIndex) =>
					changePathConfirmationHandler(PATHS.APP.PROFILE, () => {
						setValue(tabIndex)
						setHideIndicator(false)
					})
				}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
			<NavigationTab
				{...tabsInfo.menu}
				icon={<List fontSize={ICON_SIZES.md} />}
				onClick={handleToggleMenuPopover}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
		],
		[
			value,
			classes.messagesIsOn,
			classes.profileTabIcon,
			numberOfUnViewed,
			getIconColor,
			getIconWeight,
			handleToggleMessagesPopover,
			allNotificationsData,
			handleToggleNotificationPopover,
			analyticsRole,
			externalId,
			photoUrl,
			handleToggleMenuPopover,
			changePathConfirmationHandler,
		],
	)

	const mobileTabs = useMemo(
		() => [
			<NavigationTab
				{...tabsInfo.message}
				icon={
					<div className={classes.messagesIsOn}>
						<CounterBadge badgeContent={numberOfUnViewed}>
							<IconWrapper iconKey="chatsCircle" />
						</CounterBadge>
					</div>
				}
				onClick={handleToggleMessagesPopover}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
			<NavigationTab
				key="mobile-notifications"
				index={0}
				icon={
					<div className={classes.messagesIsOn}>
						<CounterBadge badgeContent={allNotificationsData ? allNotificationsData.unviewed : 0}>
							<Bell fontSize={ICON_SIZES.md} />
						</CounterBadge>
					</div>
				}
				onClick={handleToggleNotificationPopover}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
			<NavigationTab
				key="mobile-menu"
				index={1}
				icon={<List fontSize={ICON_SIZES.md} />}
				onClick={handleToggleMenuPopover}
				activeTabIndex={value}
				hideIndicator={false}
				centered={true}
			/>,
		],
		[
			allNotificationsData,
			classes.messagesIsOn,
			handleToggleMenuPopover,
			handleToggleMessagesPopover,
			handleToggleNotificationPopover,
			numberOfUnViewed,
			value,
		],
	)
	const {
		school: { shortName },
		logo: { alt: altLogo, main: mainLogo },
	} = useSchoolConfig()

	const isAnalyticsPage = pathname.includes(ADMIN_ROOT_PATH) && !!analyticsRole
	const appTheme = useTheme()
	return (
		<>
			<main className={classes.root}>
				<MenuPopover
					anchorEl={menuAnchorEl}
					header={showSettingsMenu ? 'Settings' : ''}
					handleClose={handleCloseMenu}
					renderButtons={renderSideMenuButtons}
				/>
				<NotificationsPopper anchorEl={notificationsAnchorEl} handleClose={handleCloseNotificationPopover} />
				<MessagesPopper anchorEl={messagesAnchorEl} handleClose={handleCloseMessagesPopover} />
				<Notifier />
				<Header logoAlt={shortName} logoSrc={appTheme.palette.type === 'light' ? altLogo : mainLogo} onClickLogo={handleClickLogo}>
					{isAnalyticsPage ? (
						<HeaderInfo>
							<NavigationTabs activeTabIndex={value} hideIndicator={hideIndicator} tabsRenderer={() => (matches ? mobileTabs : tabs)} />
						</HeaderInfo>
					) : (
						<NavigationTabs activeTabIndex={value} hideIndicator={hideIndicator} tabsRenderer={() => (matches ? mobileTabs : tabs)} />
					)}
				</Header>
				<div className={classes.container}>
					{sidebar}
					<ErrorBoundary key={pathname}>{children}</ErrorBoundary>
				</div>
				{!hideFooter && <Footer />}
			</main>
		</>
	)
}

export default MainLayout
