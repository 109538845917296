import { SESSION_TIME } from '@/constants/configuration'
import DtoFacultyStaff from '@/features/adminConsole/types/users/dto/DtoFacultyStaff'
import DtoStudent from '@/features/adminConsole/types/users/dto/DtoStudent'
import DtoUser from '@/features/adminConsole/types/users/dto/DtoUser'
import DtoUserAppUsage from '@/features/adminConsole/types/users/dto/DtoUserAppUsage'
import DtoUserOrgMembershipsResults from '@/features/adminConsole/types/users/dto/DtoUserOrgMembershipsResults'
import GetAnalyticsProfileParams from '@/features/adminConsole/types/users/params/GetAnalyticsProfileParams'
import GetAnalyticsUserParams from '@/features/adminConsole/types/users/params/GetAnalyticsUserParams'
import { EditAppUserTypeTemplateProps, isAppUserRow } from '../components/dataTable/templates/AppUserTypeTemplate'
import PatchUserParams from '../types/users/params/PatchUserTypeParams'
import { baseApi } from './baseApi'

export const usersApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getAnalyticsUser: builder.query<DtoUser, GetAnalyticsUserParams>({
			query: ({ userId }) => ({
				url: `/users/user/${userId}`,
				method: 'GET',
			}),

			keepUnusedDataFor: SESSION_TIME,
		}),
		getAnalyticsUserGoals: builder.query<{ history: any[] }, GetAnalyticsUserParams>({
			query: ({ userId }) => ({
				url: `/users/user/${userId}/goals`,
				method: 'GET',
			}),
		}),
		getAnalyticsUserAppUsage: builder.query<DtoUserAppUsage, GetAnalyticsUserParams>({
			query: ({ userId }) => ({
				url: `/users/user/${userId}/app-usage`,
				method: 'GET',
			}),
		}),
		getAnalyticsStudentData: builder.query<DtoStudent, GetAnalyticsProfileParams>({
			query: ({ externalId }) => ({
				url: `/users/student/${externalId}`,
				method: 'GET',
			}),
		}),
		getAnalyticsFacultyStaffData: builder.query<DtoFacultyStaff, GetAnalyticsProfileParams>({
			query: ({ externalId }) => ({
				url: `/users/faculty-staff/${externalId}`,
				method: 'GET',
			}),
		}),
		getAnalyticsUserOrgMemberships: builder.query<DtoUserOrgMembershipsResults, GetAnalyticsUserParams>({
			query: ({ userId }) => ({
				url: `/users/user/${userId}/org-memberships`,
				method: 'GET',
			}),
		}),
		patchUserType: builder.mutation<DtoUserOrgMembershipsResults, PatchUserParams | EditAppUserTypeTemplateProps['row']>({
			query: (props) => {
				const { userId, userType } = isAppUserRow(props)
					? { userId: props.id, userType: props.appUserTypeId }
					: { userId: props.userId, userType: props.userType }

				return {
					url: `/users/user/${userId}/type`,
					body: { userType },
					method: 'PATCH',
					meta: { showSuccess: true },
				}
			},
		}),
	}),
})

export const {
	useGetAnalyticsUserQuery,
	useGetAnalyticsUserAppUsageQuery,
	useGetAnalyticsUserGoalsQuery,
	useGetAnalyticsFacultyStaffDataQuery,
	useGetAnalyticsStudentDataQuery,
	useGetAnalyticsUserOrgMembershipsQuery,
	usePatchUserTypeMutation,
} = usersApi
