// @TODO: remove ts-nocheck
// @ts-nocheck
import { PrivacyLevels, PrivacyTypes } from '@/api/http/user/patchUserPrivacySettings'
import { SCHOOL_ID } from '@/constants/configuration'
import ROLE from '@/constants/roles'
import {
	setAccessToken,
	setEntityPrivacy,
	setIsLoading,
	setIsOpenAcceptNewTermsPopup,
	setIsOpenWelcomeNewUserPopup,
	setProfileTabToOpen,
	setSignUpForm,
	setUserActivity,
	setUserAfterPageRefresh,
	setUserInfo,
	setUserOrganizationRoles,
	setUserPrivacyOptions,
	signIn,
	signOut,
	signUp,
	storeLatestPrivacyPolicy,
	storeLatestTerms,
	storeSignUpFormRequest,
	toggleSignUpConnectionId,
	updateUserAcceptedTerms,
} from '@/store/auth/actions'
import { AuthState } from '@/store/auth/types'
import { resolveSchoolId } from '@/utils'
import { getUserType } from '@/utils/authHandlers'
import { createReducer } from '@reduxjs/toolkit'

const initialState: AuthState = {
	role: ROLE.GUEST,
	firstLogin: false,
	isLoading: true,
	signUpForm: {
		patches: {
			connectToUserIds: {},
		},
	},
	userInfo: {
		campusId: 0,
		id: 0,
		externalId: '',
		appUserTypeId: getUserType(ROLE.GUEST),
	},
	goalResponses: [],
	goals: [],
	preferences: {
		notifications: {
			isLoading: false,
		},
	},
	userPreferences: {
		receiveEmailsAboutProfessionalOpportunitiesAndResources: true,
		receiveEmailsAboutResearchOpportunitiesAndResources: true,
		receiveEmailsAboutVolunteerAndServiceOpportunities: true,
		receiveEmailsEducationAbroadOpportunitiesAndResources: true,
		receiveEmailsAboutEvents: true,
		receiveEmailsAboutOrganizations: true,
		notificationsConnectionRequests: true,
		notificationsUpcomingEventsGoing: true,
		notificationsUpcomingEventsInterested: true,
		notificationsLikesOnMyPosts: true,
		notificationsCommentsOnMyPosts: true,
		notificationsDirectMessages: true,
	},
	profileTabToOpen: 0,
	activity: {
		list: [],
		page: 1,
		endHasBeenReached: false,
	},
	organizationRoles: {},
	userPrivacyOptions: [],
	entityPrivacy: {
		[PrivacyTypes.volunteerHours]: PrivacyLevels.allUsers,
	},
	isOpenWelcomeNewUserPopup: false,
	isOpenAcceptNewTermsPopup: false,
	connectToUserIds: {},
	schoolId: resolveSchoolId(),
}

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(signIn, (state, { payload: { userInfo, role, token } }) => {
			state.userInfo = userInfo || state.userInfo
			state.role = role || state.role
			state.token = token || state.token
		})
		.addCase(signUp, (state, { payload: { userInfo, role, token } }) => {
			state.userInfo = userInfo || state.userInfo
			state.role = role || state.role
			state.token = token || state.token
			state.firstLogin = true
		})
		.addCase(setUserAfterPageRefresh, (state, { payload: { userInfo, role, token } }) => {
			state.userInfo = userInfo
			state.role = role
			if (token) {
				state.token = token
			}
		})
		.addCase(setAccessToken, (state, { payload: { token } }) => {
			state.token = token
		})
		.addCase(setProfileTabToOpen, (state, { payload }) => {
			state.profileTabToOpen = payload
		})
		.addCase(setIsLoading, (state, action) => {
			state.isLoading = action.payload
		})
		.addCase(signOut, () => ({
			...initialState,
			schoolId: SCHOOL_ID,
		}))
		.addCase(setSignUpForm, (state, { payload: { patches } }) => {
			state.signUpForm.patches = { ...state.signUpForm.patches, ...patches }
		})
		.addCase(storeSignUpFormRequest.fulfilled, (state) => {
			state.firstLogin = false
		})
		.addCase(setUserInfo, (state, action) => {
			state.userInfo = action.payload.userInfo ? action.payload.userInfo : state.userInfo
			state.upcomingShiftsCount = action.payload.upcomingShiftsCount || state.upcomingShiftsCount
			state.goals = action.payload.goals ?? state.goals
			state.goalResponses = action.payload.goalResponses ?? state.goalResponses
		})
		.addCase(setUserActivity, (state, { payload }) => {
			state.activity = {
				...state.activity,
				...payload,
			}
		})
		.addCase(setUserOrganizationRoles, (state, { payload }) => {
			state.organizationRoles = payload
		})
		.addCase(setUserPrivacyOptions, (state, { payload }) => {
			state.userPrivacyOptions = payload
		})
		.addCase(setEntityPrivacy, (state, { payload }) => {
			state.entityPrivacy = {
				...state.entityPrivacy,
				...payload,
			}
		})
		.addCase(setIsOpenWelcomeNewUserPopup, (state, { payload }) => {
			state.isOpenWelcomeNewUserPopup = payload
		})
		.addCase(storeLatestTerms, (state, { payload }) => {
			state.termsAndConditions = payload
		})
		.addCase(storeLatestPrivacyPolicy, (state, { payload }) => {
			state.privacyPolicy = payload
		})
		.addCase(setIsOpenAcceptNewTermsPopup, (state, { payload }) => {
			state.isOpenAcceptNewTermsPopup = payload
		})
		.addCase(updateUserAcceptedTerms, (state, { payload }) => {
			state.userInfo.acceptedTermsConditions = payload
		})
		.addCase(toggleSignUpConnectionId, (state, { payload }) => {
			const { connectToUserIds } = state.signUpForm.patches
			if (connectToUserIds[payload]) {
				delete connectToUserIds[payload]
			} else {
				connectToUserIds[payload] = true
			}
		})
})

export { authReducer }
