import { ICON_SIZES } from '@/constants/iconSizes'
import { useGetGroupsGridDataQuery, useGetGroupsGridMetaQuery, useToggleRemoveGroupsMutation } from '@/features/adminConsole/api/groupsApi'
import DataTable from '@/features/adminConsole/components/dataTable/DataTable'
import { XCircle } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'

const GroupChatList = () => {
	const [toggleRemoveGroups] = useToggleRemoveGroupsMutation()
	const [selectedRows, setSelectedRows] = useState<string[]>([])

	const handleDeleteEntities = useCallback(() => {
		toggleRemoveGroups({
			groupIds: selectedRows,
		})
		setSelectedRows([])
	}, [toggleRemoveGroups, selectedRows])

	const tableActions = useMemo(
		() => [
			{
				label: 'Remove',
				icon: <XCircle size={ICON_SIZES.sm} weight="bold" />,
				onClick: handleDeleteEntities,
				isVisible: selectedRows.length > 0,
			},
		],
		[handleDeleteEntities, selectedRows],
	)
	return (
		<DataTable
			metaQuery={useGetGroupsGridMetaQuery}
			listQuery={useGetGroupsGridDataQuery}
			setSelectedRows={setSelectedRows}
			actions={tableActions}
			selectedRows={selectedRows}
		/>
	)
}

export default GroupChatList
