import { useAppDispatch } from '@/store'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import EditOrganizationModal from '@/components/IndividualPage/EditOrganizationModal'
import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import OrganizationOwnerDashboard, { OrgDashboardActions } from '@/components/IndividualPage/OrganizationOwnerDashboard'
import OrganizationTitleCard from '@/components/IndividualPage/OrganizationTitleCard'
import PageContainer from '@/components/IndividualPage/PageContainer'
import { ReportSubject } from '@/components/ReportModal'
import FORM from '@/constants/createEventFormFields'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { ReportActions, UserResponseStatuses } from '@/interfaces/common'
import { getUserOrganizationRoles } from '@/store/auth'
import { openCreateEventPage } from '@/store/events'
import {
	createInfoRequest,
	createOrganizationMembershipRequestRequest,
	getHasPendingJoinToOrganizationRequest,
	getIsLoadingIndividualOrganization,
	getOpenOrgDefaultOptions,
	getSelectedOrganization,
	setOpenOrgDefaultOptions,
	setOrganizationMemberships,
} from '@/store/organizations'
import { loadOrganizationByIdRequest, setOrganizationResponseRequest } from '@/store/organizations/actions'
import { getMaxMembershipPermissionLevel, getOrganizationType, isAdmin, isSuperAdmin, orgTypes } from '@/utils/organizationRoles'
import { makeStyles } from '@material-ui/core/styles'

import { globalStates, useGlobalStateSetter } from '@/lib/globalState'

import ActivityTab from '@/anthology/organizations/pages/IndividualPage/ActivityTab'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import EventsTab from './EventsTab'
import ProfileTab from './ProfileTab'

enum OrganizationTabs {
	PROFILE = 'Profile',
	ACTIVITY = 'Activity',
	EVENTS = 'Events',
}

const tabIndex = {
	[OrganizationTabs.PROFILE]: 0,
	[OrganizationTabs.ACTIVITY]: 1,
	[OrganizationTabs.EVENTS]: 2,
}

const navigationTabs = Object.values(OrganizationTabs)

const useStyles = makeStyles((theme) => ({
	editButton: {
		width: 175,
		height: 50,
		marginLeft: 10,
		fontSize: 18,
		fontWeight: 550,
	},
}))

const IndividualOrganization = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useAppDispatch()
	const classes = useStyles()

	const isLoading = useSelector(getIsLoadingIndividualOrganization)
	const organization = useSelector(getSelectedOrganization)

	const userRoles = useSelector(getUserOrganizationRoles(id))
	const userRequestedToJoin = useSelector(getHasPendingJoinToOrganizationRequest(id, userRoles))

	const openOrgDefaultOptions = useSelector(getOpenOrgDefaultOptions)

	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const superAdminPermissions = isSuperAdmin(userRoles)
	const moderatorPermissions = isAdmin(userRoles)

	const userPermissionsLevel = getMaxMembershipPermissionLevel(userRoles)
	const [activeTab, setActiveTab] = useState(openOrgDefaultOptions ? -1 : tabIndex[OrganizationTabs.PROFILE])
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [activeAction, setActiveAction] = useState<OrgDashboardActions | null>(
		openOrgDefaultOptions ? openOrgDefaultOptions.activeDashboardTab : null,
	)

	useWatchPageTitle({
		watchEntity: organization?.name,
	})

	const isAdminPermissions = superAdminPermissions || moderatorPermissions || organization?.canEdit

	const canCreateFeeds = isAdminPermissions
	const canCreateEvents = isAdminPermissions

	const orgRole = superAdminPermissions ? ORGANIZATION_ROLE.SUPER_ADMIN : moderatorPermissions ? ORGANIZATION_ROLE.ADMIN : null

	// @TODO: business logic needs to be defined in backend, canLeave can be added to the org response or it cab be queried separately
	const canLeaveOrganization = !superAdminPermissions // || organizationSuperAdmins.total > 1

	const organizationName = `${organization?.name}`

	const organizationCategoriesIds = useMemo(() => organization?.categories?.map(({ id }: any) => id) ?? [], [organization?.categories])

	const handleOpenEditModal = () => setEditModalOpen(true)
	const handleCloseEditModal = () => setEditModalOpen(false)

	const handleClickCreateEvent = useCallback(() => {
		dispatch(
			openCreateEventPage({
				[FORM.hostOrganization]: [id],
			}),
		)
	}, [dispatch, id])

	const handleOpenCreateFeedModal = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.isOpen = true
			data.content.ownerOrganizationId = id
		})
	}, [id, setCreateFeedsModal])

	const handleOpenActivityTab = useCallback(() => {
		setActiveTab(tabIndex[OrganizationTabs.ACTIVITY])
	}, [])

	const tabComponentsByIndex = useMemo(() => {
		const organizationInfo = {
			photoUrl: organization?.photoUrl,
			name: organization?.name,
		}

		return {
			[tabIndex[OrganizationTabs.PROFILE]]: <ProfileTab onClickPostsInfo={handleOpenActivityTab} />,
			[tabIndex[OrganizationTabs.ACTIVITY]]: (
				<ActivityTab
					orgId={id}
					organizationInfo={organizationInfo}
					handleOpenCreateFeedModal={handleOpenCreateFeedModal}
					canCreateFeeds={canCreateFeeds}
				/>
			),
			[tabIndex[OrganizationTabs.EVENTS]]: (
				<EventsTab orgId={id} canCreateEvents={canCreateEvents} handleClickCreateEvent={handleClickCreateEvent} />
			),
		}
	}, [
		organization?.photoUrl,
		organization?.name,
		handleOpenActivityTab,
		handleOpenCreateFeedModal,
		canCreateFeeds,
		id,
		canCreateEvents,
		handleClickCreateEvent,
	])

	const activeTabComponent = useMemo(() => tabComponentsByIndex[activeTab], [activeTab, tabComponentsByIndex])

	const handleTabChange = (value: number) => {
		setActiveTab(value)
		setActiveAction(null)
	}

	const handleSelectAdminAction = (action: OrgDashboardActions) => {
		setActiveTab(-1)
		setActiveAction(action)
	}

	const handleToggleResponse = () => {
		const isFollow = !organization?.followed
		dispatch(
			setOrganizationResponseRequest({
				id,
				status: isFollow ? UserResponseStatuses.followed : UserResponseStatuses.cleared,
			}),
		)
	}

	const handleReportOrganization = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setOrganizationResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)

	const handleCreateRequestToJoin = useCallback(() => {
		dispatch(
			createInfoRequest({
				orgId: id,
				membershipTypeId: getOrganizationType(ORGANIZATION_ROLE.MEMBER),
			}),
		)
	}, [dispatch, id])

	const handleClaimMembership = useCallback(() => {
		dispatch(
			createOrganizationMembershipRequestRequest({
				orgId: id,
				membershipTypeId: getOrganizationType(ORGANIZATION_ROLE.MEMBER),
			}),
		)
	}, [dispatch, id])

	const handleRequestToChangePosition = useCallback(
		(membershipTypeId: orgTypes) => {
			dispatch(
				createOrganizationMembershipRequestRequest({
					orgId: id,
					membershipTypeId,
				}),
			)
		},
		[dispatch, id],
	)

	useEffect(() => {
		dispatch(loadOrganizationByIdRequest(id))

		return () => {
			dispatch(setOrganizationMemberships({}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const scrollTarget = openOrgDefaultOptions ? document.getElementById(openOrgDefaultOptions.scrollTo) : null

	useEffect(() => {
		if (openOrgDefaultOptions && organization?.id && !isLoading && scrollTarget) {
			handleSelectAdminAction(openOrgDefaultOptions.activeDashboardTab)

			setTimeout(() => {
				scrollTarget?.scrollIntoView({ block: 'start' })
			}, 300)

			dispatch(setOpenOrgDefaultOptions(null))
		}
	}, [organization?.id, isLoading, openOrgDefaultOptions, scrollTarget, dispatch])

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.organization } }), [id])

	if (!organization?.id || isLoading) return <LoadingPlaceholder />

	return (
		<PageContainer>
			<EditOrganizationModal
				isOpen={editModalOpen}
				onClose={handleCloseEditModal}
				dataLake={organization.dataLake}
				sourceSystem={organization.sourceSystem}
				orgInfo={{
					id: organization.id,
					name: organization?.name,
					categories: organizationCategoriesIds,
					description: organization?.description,
					websiteUrl: organization?.websiteUrl,
					twitterUrl: organization?.twitterUrl,
					linkedinUrl: organization?.linkedinUrl,
					instagramUrl: organization?.instagramUrl,
					facebookUrl: organization?.facebookUrl,
					photoUrl: organization?.photoUrl,
				}}
			/>
			<ImageCard
				dotButton={{
					itemId: organization.id,
					reportSubject: ReportSubject.Organization,
					onSubmitReport: handleReportOrganization,
				}}
				photoUrl={organization.photoUrl}
				isAdmin={isAdminPermissions}
				editPageElement={
					isAdminPermissions ? (
						<ColorButton className={classes.editButton} color={ButtonColors.YELLOW} onClick={handleOpenEditModal}>
							Edit Page
						</ColorButton>
					) : null
				}
			/>
			<OrganizationTitleCard
				id={id}
				title={organizationName}
				followed={organization?.followed}
				dataLake={organization.dataLake}
				externalUrl={organization.websiteUrl}
				activeTab={activeTab}
				handleToggleResponse={handleToggleResponse}
				tabs={navigationTabs}
				handleChangeTab={handleTabChange}
				sharedData={sharedData}
				handleCreateRequestToJoin={handleCreateRequestToJoin}
				handleClaimMembership={handleClaimMembership}
				userRole={userPermissionsLevel?.role}
				userRequestedToJoin={userRequestedToJoin}
				userMembershipTypeId={userPermissionsLevel?.type}
				handleRequestToChangePosition={handleRequestToChangePosition}
				canLeaveOrganization={canLeaveOrganization}
			/>
			{orgRole && (
				<OrganizationOwnerDashboard
					isDataLakeOrg={organization?.dataLake}
					sourceSystem={organization.sourceSystem ? organization.sourceSystem : undefined}
					orgId={organization.id}
					role={orgRole}
					onSelectAction={handleSelectAdminAction}
					activeAction={activeAction}
					currentUserRole={orgRole}
					handleOpenCreateFeedModal={handleOpenCreateFeedModal}
					handleClickCreateEvent={handleClickCreateEvent}
					membershipTypeId={userPermissionsLevel!.type}
				/>
			)}
			{activeTabComponent}
		</PageContainer>
	)
}

export default IndividualOrganization
