import { Box } from '@mui/material'
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { ActionButtonProps } from '../../types/dashboardTypes'
import ToolBarButton from './ToolBarButton'

export type ToolbarProps = {
	actions: ActionButtonProps[] // @TODO: Leverage in Form
	isFilterEnabled: boolean
}

export const Toolbar = (props: ToolbarProps) => {
	return (
		<GridToolbarContainer sx={{ marginTop: '1.2rem' }}>
			<GridToolbarColumnsButton />
			{props.isFilterEnabled && <GridToolbarFilterButton />}
			<GridToolbarExport />
			<GridToolbarDensitySelector />

			<Box sx={{ flexGrow: 1 }} />
			{(props.actions ?? [])
				.filter((action) => !!action.isVisible)
				.map((action, index) => (
					<ToolBarButton {...action} index={index} />
				))}
		</GridToolbarContainer>
	)
}
