import { EntityId, EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { RootState } from '@/store'
import EntityTypes from '../shareEntity/types/EntityTypes'
import { onboardingApi } from './api'
import DtoOnboardingEntity from './types/dto/DtoOnboardingEntity'
import { TOnboardingEntitiesParams } from './types/queryParams/GetOnboardingEntitiesParams'

export const FEATURE_NAME = 'ONBOARDING'

export type TOnboardingEntity = DtoOnboardingEntity & { checked: boolean }

export const onboardingEntityAdapter = createEntityAdapter<TOnboardingEntity>()

const mapToSelection =
	(entityType: EntityTypes, ids: Array<number | string>) =>
	(item: DtoOnboardingEntity): TOnboardingEntity => ({
		...item,
		checked: ids.indexOf(item.id) > -1 ? true : false,
	})

type ListState = { total: number; isLoading: boolean; skip: number; canLoadMore: boolean; isUninitialized: boolean }

type TOnboardingState = {
	userChoices: {
		groupChatChoiceIds: Array<EntityId>
		academicUnitChoiceIds: Array<EntityId>
		organizationChoiceIds: Array<EntityId>
		connectToUserIds: Array<EntityId>
		majorIds: Array<EntityId>
		academicCollegeIds: Array<EntityId>
		opportunityIds: Array<EntityId>
		experienceIds: Array<EntityId>
		interestIds: Array<EntityId>
	}
	isModerating: boolean
	data: EntityState<TOnboardingEntity> & { list: ListState }
}

type TUserChoicePath = keyof TOnboardingState['userChoices']

type GetChoiceIdsNameByTypeParams = {
	entityType: EntityTypes
	entityScope?: TOnboardingEntitiesParams['entityScope']
}

const getChoiceInfoByType = ({
	entityType,
	entityScope,
}: GetChoiceIdsNameByTypeParams): { path: TUserChoicePath | null; multiSelect: boolean } => {
	let userChoicePath: TUserChoicePath = null
	let multiSelect = true
	switch (entityType) {
		case EntityTypes.organization:
			userChoicePath = entityScope && entityScope === 'academic' ? 'organizationChoiceIds' : 'academicUnitChoiceIds'
			break
		case EntityTypes.group:
			userChoicePath = 'groupChatChoiceIds'
			break
		case EntityTypes.user:
			userChoicePath = 'connectToUserIds'
			break
		case EntityTypes.major:
			userChoicePath = 'majorIds'
			multiSelect = false
			break
		case EntityTypes.academicCollege:
			userChoicePath = 'academicCollegeIds'
			multiSelect = false
			break
		case EntityTypes.opportunities:
			userChoicePath = 'opportunityIds'
			break
		case EntityTypes.experience:
			userChoicePath = 'experienceIds'
			break
		case EntityTypes.interest:
			userChoicePath = 'interestIds'
			break
		default:
			Sentry.captureException(new Error(`Entity type ${entityType} not supported`))
			break
	}
	return { path: userChoicePath, multiSelect }
}

const ADAPTER_LIST_INITIAL_STATE: ListState = {
	isLoading: false,
	canLoadMore: false,
	total: 0,
	skip: 0,
	isUninitialized: true,
}

const initialState: TOnboardingState = {
	userChoices: {
		groupChatChoiceIds: [],
		academicUnitChoiceIds: [],
		organizationChoiceIds: [],
		connectToUserIds: [],
		majorIds: [],
		academicCollegeIds: [],
		opportunityIds: [],
		experienceIds: [],
		interestIds: [],
	},
	isModerating: false,
	data: onboardingEntityAdapter.getInitialState({ list: { ...ADAPTER_LIST_INITIAL_STATE } }),
}

export const onboardingSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		toggleSelect: (
			state,
			{
				payload: { entityId, entityType, entityScope },
			}: PayloadAction<{ entityType: EntityTypes; entityScope?: TOnboardingEntitiesParams['entityScope']; entityId: EntityId }>,
		) => {
			const { multiSelect, path } = getChoiceInfoByType({ entityType, entityScope })

			if (path && state.userChoices[path]) {
				const userChoiceArray = state.userChoices[path]

				const index = userChoiceArray.indexOf(entityId)

				if (!multiSelect) {
					if (userChoiceArray.length > 0) {
						onboardingEntityAdapter.updateOne(state.data, { changes: { checked: false }, id: userChoiceArray[0] })
					}
					if (index > -1) {
						state.userChoices[path] = []
					} else {
						state.userChoices[path] = [entityId]
					}
				} else {
					if (index > -1) {
						userChoiceArray.splice(index, 1)
					} else {
						userChoiceArray.push(entityId)
					}
				}

				onboardingEntityAdapter.updateOne(state.data, { changes: { checked: index === -1 }, id: entityId })
			}
		},
		clearAdapterState: (state) => {
			state.data.list = { ...ADAPTER_LIST_INITIAL_STATE }
			onboardingEntityAdapter.removeAll(state.data)
		},
		setIsModerating: (state, { payload }: PayloadAction<boolean>) => {
			state.isModerating = payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addMatcher(
				isAnyOf(onboardingApi.endpoints.getOnboardingEntities.matchFulfilled),
				(state, { payload: { items, totalItems, entityType, campusId, entityScope, skip } }) => {
					const fn = skip === 0 ? 'setAll' : 'upsertMany'
					const { path } = getChoiceInfoByType({ entityType, entityScope })

					onboardingEntityAdapter[fn](state.data, items.map(mapToSelection(entityType, state.userChoices[path])))
					state.data.list.total = totalItems
					state.data.list.canLoadMore = totalItems > state.data.ids.length
					state.data.list.isLoading = false
					state.data.list.skip = skip
				},
			)
			.addMatcher(isAnyOf(onboardingApi.endpoints.getOnboardingEntities.matchPending), (state) => {
				if (state.data.ids.length === 0) {
					state.data.list.isLoading = true
				}
			})
			.addMatcher(isAnyOf(onboardingApi.endpoints.getOnboardingEntities.matchRejected), (state) => {
				state.data.list.isLoading = false
			}),
})

const selectState = (state: RootState) => state[FEATURE_NAME]

export const selectOnboardingEntitiesState = (state: RootState) => state[FEATURE_NAME].data
export const selectOnboardingUserChoices = createSelector(selectState, (state) => state.userChoices)
export const onboardingEntitySelectors = onboardingEntityAdapter.getSelectors(selectOnboardingEntitiesState)

export const selectIds = (state: RootState) => onboardingEntitySelectors.selectIds(state)
export const selectAll = (state: RootState) => onboardingEntitySelectors.selectAll(state)
export const selectCanLoadMore = createSelector(selectState, (state) => state.data.list.canLoadMore)
export const selectIsLoading = createSelector(selectState, (state) => state.data.list.isLoading)
export const selectIsUninitialized = createSelector(selectState, (state) => state.data.list.isUninitialized)
export const selectIsModerating = createSelector(selectState, (state) => state.isModerating)

export const selectUserChoicesByType = createSelector(
	(state: RootState) => state,
	(__, params: GetChoiceIdsNameByTypeParams): ReturnType<typeof getChoiceInfoByType> => getChoiceInfoByType(params),
	(state: RootState, { path }: ReturnType<typeof getChoiceInfoByType>) => {
		if (path && state[FEATURE_NAME].userChoices[path]) {
			return state[FEATURE_NAME].userChoices[path]
				.map((id) => {
					const data = onboardingEntitySelectors.selectById(state, id)
					return data
				})
				.filter((data) => !!data)
		}
		return []
	},
)
