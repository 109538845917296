import { ReactNode } from 'react'

import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { GridCellParams } from '@mui/x-data-grid'
import { LearnMoreProps } from '../../../components/Modal/AdminModalContent'
import { SignupDashboardTableProps } from '../components/dataTable/SignupDashboardTable'
import { EditableOnboardingSteps } from './EditableOnboardingSteps.enum'

export enum DashboardType {
	FEED = 'feed',
	FEED_COMMENTS = 'feedComments',
	HOME = 'home',
	EVENTS = 'events',
	USERS = 'users',
	PATH_BUILDER = 'pathBuilder',
	ORGANIZATIONS = 'organizations',
}

export type DataTableConfig = {
	label: string
	entityType: EntityTypes
	entityScope?: EntityScope
	description?: string
	onboardingStep?: EditableOnboardingSteps
	learnMoreProps?: LearnMoreProps
	deleteMutation: SignupDashboardTableProps['deleteMutation']
}

export type ActionButtonProps = {
	label: ReactNode
	onClick?: () => void
	icon?: ReactNode
	isVisible?: boolean
	helperText?: string | ReactNode
}

export const isGridCellParams = (args: any): args is GridCellParams => args.hasOwnProperty('field') && args.hasOwnProperty('value')
