import { Id } from '@/lib/types'
import EVENTS from './events'
import HOME from './home'
import INTERNSHIPS from './internships'
import MESSAGES from './messages'
import NETWORK from './network'
import NOTIFICATIONS from './notifications'
import ORGANIZATIONS from './organizations'
import PATH_BUILDER from './pathBuilder'
import RESEARCH from './research'
import SETTINGS from './settings'
import EDUCATION_ABROAD from './studyAbroad'
import VOLUNTEERS from './volunteers'

const ROOT = '/app' as const

const MAIN = {
	ROOT,
	ADMIN: `${ROOT}/admin`,
	EVENTS: `${ROOT}/${EVENTS.ROOT}`,
	EVENTS_MY: `${ROOT}/${EVENTS.MY}`,
	EVENTS_SINGLE: (id?: string | number) => `${ROOT}/${EVENTS.SINGLE(id)}`,
	EVENTS_CREATE: `${ROOT}/${EVENTS.CREATE}`,
	EVENTS_EDIT: (id?: string) => `${ROOT}/${EVENTS.SINGLE(id)}/edit`,
	ORGANIZATIONS: `${ROOT}/${ORGANIZATIONS.ROOT}`,
	ORGANIZATIONS_MY: `${ROOT}/${ORGANIZATIONS.MY}`,
	// @TODO: #uuid type conflict
	ORGANIZATIONS_SINGLE: (id?: string | number) => `${ROOT}/${ORGANIZATIONS.SINGLE(id)}`,
	VOLUNTEERS_EVENTS: `${ROOT}/${VOLUNTEERS.EVENTS}`,
	VOLUNTEERS_ORGANIZATIONS: `${ROOT}/${VOLUNTEERS.ORGANIZATIONS}`,
	VOLUNTEERS_SHIFTS: `${ROOT}/${VOLUNTEERS.MY}`,
	VOLUNTEER: `${ROOT}/${VOLUNTEERS.ROOT}`,
	PROFILE: `${ROOT}/profile`,
	RESEARCH: `${ROOT}/${RESEARCH.ROOT}`,
	RESEARCH_MY: `${ROOT}/${RESEARCH.MY}`,
	RESEARCH_SINGLE: (id?: string) => `${ROOT}/${RESEARCH.SINGLE(id)}`,
	EDUCATION_ABROAD: `${ROOT}/${EDUCATION_ABROAD.ROOT}`,
	EDUCATION_ABROAD_MY: `${ROOT}/${EDUCATION_ABROAD.MY}`,
	EDUCATION_ABROAD_SINGLE: (id?: string) => `${ROOT}/${EDUCATION_ABROAD.SINGLE(id)}`,
	HOME: `${ROOT}/${HOME.ROOT}`,
	NETWORK_MY: `${ROOT}/${NETWORK.MY}`,
	NETWORK_SEARCH: `${ROOT}/${NETWORK.SEARCH}`,
	NETWORK: `${ROOT}/${NETWORK.ROOT}`,
	NETWORK_USER: (id?: string) => `${ROOT}/${NETWORK.USER(id)}`,
	NOTIFICATIONS: (type?: string) => `${ROOT}/${NOTIFICATIONS.ROOT}/${type ? type : ':type?'}`,
	POST_INFO: (id?: Id | string) => `${ROOT}/${HOME.POST_INFO(id)}`,
	MESSAGES: `${ROOT}/${MESSAGES.ROOT}`,
	MESSAGES_LIST: `${ROOT}/${MESSAGES.LIST}`,
	MESSAGES_USER_DIALOGUE: (id?: number) => `${ROOT}/${MESSAGES.USER_DIALOGUE(id)}`,
	MESSAGES_GROUP: (id?: string) => `${ROOT}/${MESSAGES.GROUP(id)}`,
	DISCOVER_GROUP: `${ROOT}/${MESSAGES.DISCOVER_GROUP}`,
	PATH_BUILDER: `${ROOT}/${PATH_BUILDER.ROOT}`,
	PATH_BUILDER_QUESTIONS: `${ROOT}/${PATH_BUILDER.QUESTIONS}`,
	PATH_BUILDER_GRANT: `${ROOT}/${PATH_BUILDER.GRANT}`,
	PATH_BUILDER_SUMMARY: `${ROOT}/${PATH_BUILDER.SUMMARY}`,
	PATH_BUILDER_LAST_STEP: `${ROOT}/${PATH_BUILDER.LAST_STEP}`,
	INTERNSHIPS: `${ROOT}/${INTERNSHIPS.ROOT}`,
	INTERNSHIPS_MY: `${ROOT}/${INTERNSHIPS.MY}`,
	INTERNSHIPS_SINGLE: (id?: string) => `${ROOT}/${INTERNSHIPS.SINGLE(id)}`,
	SETTINGS: `${ROOT}/${SETTINGS.ROOT}`,
	SETTINGS_BLOCKED_ACCOUNTS: `${ROOT}/${SETTINGS.BLOCKED_ACCOUNTS}`,
	SETTINGS_MUTED_ACCOUNTS: `${ROOT}/${SETTINGS.MUTED_ACCOUNTS}`,
	SETTINGS_PROFILE: `${ROOT}/${SETTINGS.PROFILE}`,
	SETTINGS_NOTIFICATIONS: `${ROOT}/${SETTINGS.NOTIFICATIONS}`,
} as const

export default MAIN
