import { LinearProgress, Stack, styled, Typography } from '@mui/material'
import { DataGrid, GridFilterPanel, GridRowModel, useGridApiContext } from '@mui/x-data-grid'
import { Funnel, SortDescending } from '@phosphor-icons/react'
import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseMutation, UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import React, { useMemo } from 'react'
import { JsonParam, useQueryParams, withDefault } from 'use-query-params'

import { IconButton } from '@/components'
import { ContentPreloader } from '@/components/ContentPreloader'
import { ICON_SIZES } from '@/constants/iconSizes'
import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { ActionButtonProps } from '../../types/dashboardTypes'
import { GridConfigDto } from '../../types/dto/Grid'
import { CommonCard } from '../CommonCard'
import CustomActionsContainer from '../CustomActionsContainer'
import { useDataTable } from '../hooks/useDataTable'
import withRoleBasedErrors from '../withRoleBasedErrors'
import { Toolbar, ToolbarProps } from './Toolbar'

export type MutationResolutionState = {
	resolve: (value: GridRowModel) => void
	reject: (reason?: any) => void
	newRow: GridRowModel
	oldRow: GridRowModel
}

export type TDataTableProps = {
	mutationDefinition?: UseMutation<any>
	metaQuery: UseQuery<QueryDefinition<any, any, any, GridConfigDto>>
	metaQueryParams?: any
	listQuery: UseQuery<QueryDefinition<any, any, any, any>>
	listQueryParams?: Record<string, any>
	isFilterEnabled?: boolean
	entityScope?: EntityScope
	entityType?: EntityTypes
	selectedRows?: string[]
	setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>
	description?: string
	actions?: ActionButtonProps[]
}

const StyledDataGrid = styled(DataGrid)<{}>(({ theme }) => ({
	maxHeight: '80%',
	maxWidth: '100%',
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 700,
	},
	'&.MuiDataGrid-root': {
		border: 'none',
		minHeight: '100%',
	},
	'& .MuiDataGrid-cell a': {
		color: theme.palette.primary.main,
		textDecoration: 'none',
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

function CustomFilterPanel() {
	const [, setSearchQuery] = useQueryParams({
		filters: withDefault(JsonParam, undefined),
	})
	const apiRef = useGridApiContext()

	const applyFilters = () => {
		setSearchQuery({ filters: apiRef.current?.state?.filter.filterModel }, 'pushIn')
		apiRef.current.hideFilterPanel()
	}

	return (
		<div>
			<GridFilterPanel />
			<CustomActionsContainer>
				<IconButton variant="primary" size="sm" onClick={applyFilters}>
					Apply
				</IconButton>
			</CustomActionsContainer>
		</div>
	)
}

// @TODO: Add validation to column limit
// @TODO: Confirmation toast on deletion: https://mui.com/x/react-data-grid/editing/#confirm-before-saving
// @TODO: (performance) Debounce in all editable fields: https://mui.com/x/react-data-grid/editing/#with-debounce
const DataTable = ({ description, isFilterEnabled = true, actions, ...tableConfig }: TDataTableProps) => {
	const table = useDataTable(tableConfig)

	const slots = useMemo(
		() => ({
			loadingOverlay: LinearProgress as any,
			noResultsOverlay: () => (
				<Stack height="100%" alignItems="center" justifyContent="center">
					No results with specified filters, try expanding your search.
				</Stack>
			),
			noRowsOverlay: () => (
				<Stack height="100%" alignItems="center" justifyContent="center">
					No results found.
				</Stack>
			),
			filterPanel: CustomFilterPanel,
			toolbar: Toolbar,
		}),
		[],
	)

	const slotProps = useMemo(
		() => ({
			toolbar: {
				actions: [
					{
						label: 'Clear Filters',
						icon: <Funnel size={ICON_SIZES.sm} weight="bold" />,
						onClick: table.clearFilters,
						isVisible: isFilterEnabled && !!table.filters,
					},
					{
						label: 'Clear Sort',
						icon: <SortDescending size={ICON_SIZES.sm} weight="bold" />,
						onClick: table.clearSort,
						isVisible: !!table.sortModel,
					},
					...(actions ?? []),
				],
				isFilterEnabled,
			} satisfies ToolbarProps,
		}),
		[isFilterEnabled, table.sortModel, table.filters, table.clearFilters, table.clearSort, actions],
	)

	return (
		<CommonCard style={{ paddingBottom: 'max(30px, 2%)', maxHeight: '92%', maxWidth: '84vw', marginBottom: '4%', boxSizing: 'border-box' }}>
			<div style={{ height: 600, width: '100%', marginTop: '16px', position: 'relative' }}>
				{table.isConfigLoading ? (
					<ContentPreloader />
				) : (
					<>
						<Typography variant="body2" style={{ paddingRight: 16 }}>
							{description ??
								'Exporting to .csv will only export what is shown on this page (up to 100 rows). Please download multiple reports or contact Customer Support if you need a larger export.'}
						</Typography>
						<StyledDataGrid
							apiRef={table.apiRef}
							slots={slots}
							slotProps={slotProps}
							loading={table.areRowsLoading}
							rows={table.rows}
							columns={table.columns}
							disableColumnFilter={!isFilterEnabled}
							filterMode="server"
							sortingMode="server"
							sortModel={table.sortModel}
							onSortModelChange={table.handleSortModelChanged}
							editMode="row"
							processRowUpdate={table.handleRowUpdate}
							paginationMode="server"
							paginationModel={table.paginationModel}
							onPaginationModelChange={table.handlePaginationModelChange}
							columnVisibilityModel={table.columnVisibilityModel}
							onColumnVisibilityModelChange={table.handleColumnVisibilityModelChange}
							isCellEditable={(params) => params.row.isEditable ?? false}
							isRowSelectable={(params) => params.row.isRowSelectable ?? true}
							pageSizeOptions={table.pageSizeOptions}
							rowCount={table.rowCount}
							{...table.rowSelectionProps}
						/>
					</>
				)}
			</div>
		</CommonCard>
	)
}

export const DataTableWithRoleCheck = withRoleBasedErrors(React.memo(DataTable))
export default React.memo(DataTable)
