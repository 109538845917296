const ROOT = 'events' as const

const EVENTS = {
	ROOT,
	MY: `${ROOT}/my`,
	SINGLE: (id?: string | number) => `${ROOT}/event/${id !== undefined ? id : ':id'}`,
	CREATE: `${ROOT}/create`,
} as const

export default EVENTS
