import { makeStyles, Typography } from '@material-ui/core'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp, GridToolbar } from '@mui/x-data-grid'
import { push } from 'connected-react-router'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import { PATHS } from '@/constants'
import settings from '@/constants/http'
import { useGetMessagesQuery, useGetPostsQuery, useToggleHideMessageMutation } from '@/features/adminConsole/api/contentModerationApi'
import { useToggleHidePostMutation } from '@/features/adminConsole/api/feedApi'
import { getUserInfo } from '@/store/auth'
import { formatDateNew } from '@/utils/dateTime2'
import { EntityId } from '@reduxjs/toolkit'
import { addSelection, clearSelection, removeSelection, selectDashboardIds } from '../slice'

enum Content {
	Posts = 'Posts',
	Comments = 'Comments',
	Messages = 'Messages',
}

//@TODO: Post and Message columns can be generated dynamically based on the data returned from the query
const postColumns = [
	{ field: 'postId', headerName: 'Post Id', width: 150 },
	{ field: 'content', headerName: 'Content', width: 450 },
	{ field: 'name', headerName: 'Name', width: 150 },
	{ field: 'email', headerName: 'Email', width: 150 },
	{ field: 'postedAt', headerName: 'Posted At', width: 225 },
	{ field: 'deleted', headerName: 'Hidden', width: 75 },
]

const messageColumns = [
	{ field: 'id', headerName: 'Message Id', width: 150 },
	{ field: 'groupName', headerName: 'Group Chat Name', width: 150 },
	{ field: 'content', headerName: 'Content', width: 450 },
	{ field: 'name', headerName: 'Name', width: 150 },
	{ field: 'email', headerName: 'Email', width: 150 },
	{ field: 'postedAt', headerName: 'Sent At', width: 225 },
	{ field: 'deleted', headerName: 'Hidden', width: 75 },
]

export type TDataPanelProps = {
	title: Content
	columns: GridColDef[]
	activeTab: number
	index: number
	query: typeof useGetPostsQuery | typeof useGetMessagesQuery
	mutation?: typeof useToggleHidePostMutation | typeof useToggleHideMessageMutation
	sanitizationMethod?: (value: any) => GridRowsProp
}

const sanitizeRow = (
	item: Record<string, any> & {
		firstName?: string
		lastName?: string
		message: string
		createdAt: string | Date
		postId?: string
		messageId?: string
	},
): GridRowsProp[0] => ({
	...item,
	name: (item?.firstName ?? '') + ' ' + (item?.lastName ?? ''),
	content: item.message.replace(/<[^>]*>?/gm, ''),
	postedAt: formatDateNew(item.createdAt),
	id: Object.keys(item).includes('postId') ? item.postId : item.messageId,
})

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(8),
	},
	navTabs: {
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
		flexGrow: 0,
	},
	tab: {
		'&.Mui-selected': {
			color: theme.palette.primary.main,
		},
	},
	tabTitle: {
		marginBottom: theme.spacing(2),
	},
	tabBody: {
		marginTop: theme.spacing(2),
	},
	deleteButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: theme.spacing(2),
		height: 50,
		paddingX: theme.spacing(2),
		alignItems: 'center',
	},
	deleteButton: {},
}))

// @TODO: We should be able to consolidate these functions into one

const tabs = [Content.Posts, Content.Messages]

//@TODO: THIS BELONGS IN ITS OWN COMPONENT
const DataPanel: React.FC<TDataPanelProps> = ({ activeTab, title, index, columns, query, mutation, sanitizationMethod }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const selectedItems = useSelector(selectDashboardIds)

	const [rows, setRows] = useState<GridRowsProp>([])
	const [count, setCount] = useState(0)

	const PAGE_SIZE = settings.DEFAULT_MODERATION_DASH_PAGE_SIZE
	const pageSizeOptions = [PAGE_SIZE, 20, 100] as [10, 20, 100]
	const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
		page: 0,
		pageSize: PAGE_SIZE,
	})

	const { data, refetch, isLoading, isFetching } = query({
		skip: paginationModel.page * PAGE_SIZE,
		take: PAGE_SIZE,
	})

	const [mutate, { isLoading: isMutationLoading, isSuccess }] = mutation()

	useEffect(() => {
		if (activeTab === index && data && !isLoading && !isFetching) {
			setRows((sanitizationMethod ? sanitizationMethod(data.items) : data?.items) ?? [])
			setCount(data.totalItems ? parseInt(data.totalItems as string) : 0)
		}
	}, [activeTab, index, data, title, isMutationLoading, isLoading, isSuccess, isFetching, sanitizationMethod])

	useEffect(() => {
		setCount((prevCount) => (data?.totalItems !== undefined ? parseInt(data.totalItems) : prevCount))
	}, [setCount, data])

	const handleUpdateClick = async () => {
		if (mutation) {
			mutate({ ids: selectedItems as string[] })
			refetch()
			dispatch(clearSelection())
		}
	}

	return (
		<div>
			<div className={classes.deleteButtonContainer}>
				<Typography variant="body2" style={{ paddingRight: 16 }}>
					Exporting to .csv will only export what is shown on this page (up to 100 rows). Please download multiple reports or contact
					Customer Support if you need a larger export.
				</Typography>

				{selectedItems.length ? (
					<ColorButton className={classes.deleteButton} color={ButtonColors.PRIMARY} onClick={handleUpdateClick}>
						Update {selectedItems.length > 1 ? 'all' : ''}
					</ColorButton>
				) : null}
			</div>
			<DataGrid
				checkboxSelection
				onRowSelectionModelChange={(newSelection) => {
					const removedIds = selectedItems.filter((id) => !newSelection.includes(id))
					const addedIds = newSelection.filter((id) => !selectedItems.includes(id))

					removedIds.forEach((id) => {
						dispatch(removeSelection(id))
					})
					addedIds.forEach((id) => {
						dispatch(addSelection({ id } as { id: string }))
					})
				}}
				getRowHeight={() => 'auto'}
				slots={{ toolbar: GridToolbar }}
				getEstimatedRowHeight={() => 200}
				paginationModel={paginationModel}
				paginationMode="server"
				onPaginationModelChange={setPaginationModel}
				pageSizeOptions={pageSizeOptions}
				rowSelectionModel={selectedItems as GridRowSelectionModel}
				rows={rows}
				loading={isLoading}
				rowCount={count}
				columns={columns}
				sx={{
					'&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
					'&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
					'&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
				}}
			/>
		</div>
	)
}

export const RemoveContentPage = () => {
	const userInfo = useSelector(getUserInfo)
	const dispatch = useDispatch()

	const classes = useStyles()
	const [activeTab, setActiveTab] = useState(0)

	// @TODO: We should move these to a custom hook leveraging RTK, memoized etc

	// Ugly little workaround to push users out if they're not supposed to be here
	useEffect(() => {
		if (!userInfo.analyticsRole) {
			dispatch(push(PATHS.ROOT))
		}
	}, [dispatch, userInfo.analyticsRole])

	const handleChangeTab = (event: SyntheticEvent<Element, Event>, newValue: number) => {
		setActiveTab(newValue)
	}

	const renderTab = useCallback(
		(tab, index) => {
			let props: TDataPanelProps = { title: tab, index, activeTab, columns: [], query: useGetPostsQuery }

			switch (tab as Content) {
				case Content.Posts:
					props = {
						...props,
						columns: postColumns,
						mutation: useToggleHidePostMutation,
						query: useGetPostsQuery,
						sanitizationMethod: (items) => items.map((post) => sanitizeRow(post)),
					}
					break
				case Content.Messages:
					props = {
						...props,
						columns: messageColumns,
						mutation: useToggleHideMessageMutation,
						query: useGetMessagesQuery,
						sanitizationMethod: (items) => items.map((message) => sanitizeRow(message)),
					}
					break
			}

			return activeTab === index ? <DataPanel key={index} index={index} {...props} /> : <div key={index}></div>
		},
		[activeTab],
	)

	return (
		// @TODO: THIS BELONGS IN ITS OWN COMPONENT; should be a PageLayout
		<div className={classes.root}>
			<Typography variant="h1">Moderate User Content</Typography>
			<Typography className={classes.tabBody} variant="body1">
				Use this dashboard to hide hateful or harmful content from students. Don't worry, you can always bring the post or message back by
				toggling the "Hidden" column again. <strong>To see updated data, please refresh your page.</strong>
			</Typography>
			<Typography className={classes.tabBody} variant="body1">
				Note - this "Hidden" field is actually the same as a user deleting their own post, so if you see posts that are "Hidden" that are
				not hateful or harmful content, it may just be a post or message that the user deleted themselves. Messages here is defined as only
				"messages sent to Public Group Chats", not Restricted or Private Group Chats nor direct messages.
			</Typography>
			<Tabs
				className={classes.navTabs}
				value={activeTab}
				onChange={handleChangeTab}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
			>
				{tabs.map((tab, index) => (
					<Tab key={index} className={classes.tab} label={tab} />
				))}
			</Tabs>
			{tabs.map(renderTab)}
		</div>
	)
}

export default RemoveContentPage
