import useSchoolId from '@/features/school/hooks/useSchoolId'
import useAuth from '@/hooks/useAuth'
import * as DIContainer from '@/init/DIContainer'
import { IconButton, Snackbar, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState } from 'react'
import { ColorButton } from '../Buttons'
import { Modal } from './Modal'

interface TokenModalProps {
	isOpen: boolean
	onClose: () => void
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		textAlign: 'center',
		marginBottom: 15,
	},
	textContainer: {
		marginTop: 5,
		width: '90%',
		textAlign: 'left',
	},
	card: {
		width: 750,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		[theme.breakpoints.down('xs')]: {
			width: '95vw',
			minWidth: 250,
		},
	},
	tokenRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginBottom: 10,
	},
	tokenLabel: {
		marginRight: 8,
		fontWeight: 'bold',
	},
	iconButton: {
		padding: 5,
	},
	button: {
		width: 220,
		height: 50,
		margin: '5px 0',
	},
	tokenValue: {
		maxWidth: '90%',
		wordBreak: 'break-all',
		overflowWrap: 'break-word',
		marginRight: 8,
	},
}))

const TokenModal: React.FC<TokenModalProps> = ({ isOpen, onClose }) => {
	const classes = useStyles()
	const [copiedToken, setCopiedToken] = useState<string | null>(null)
	const { userId } = useAuth()

	const handleCopyToClipboard = async (value: string, label: string) => {
		try {
			await navigator.clipboard.writeText(value)
			setCopiedToken(label)
			setTimeout(() => setCopiedToken(null), 2000)
		} catch (error) {
			console.error('Failed to copy to clipboard:', error)
		}
	}

	const authService = DIContainer.getAuthService()

	const accessToken = authService.getStorageData('accessToken')
	const refreshToken = authService.getStorageData('refreshToken')
	const schoolId = useSchoolId()

	const entities = {
		accessToken,
		refreshToken,
		schoolId,
		userId,
	}

	if (!isOpen) return null

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className={classes.card}>
				<Typography className={classes.title} variant="h5">
					Tokens for Local Mobile Dev
				</Typography>

				<div className={classes.textContainer}>
					{Object.entries(entities).map(([label, value]) => (
						<div key={label} className={classes.tokenRow}>
							<Typography className={classes.tokenLabel}>{label.charAt(0).toUpperCase() + label.slice(1)}:</Typography>
							<Typography variant="body2" className={classes.tokenValue}>
								{value}
							</Typography>
							<Tooltip title="Copy to clipboard">
								<IconButton className={classes.iconButton} onClick={() => handleCopyToClipboard(value.toString(), label)}>
									<FileCopyIcon fontSize="small" />
								</IconButton>
							</Tooltip>
							{copiedToken === label && (
								<Snackbar open={copiedToken === label} message="Copied" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />
							)}
						</div>
					))}
				</div>

				<ColorButton className={classes.button} onClick={onClose}>
					Close
				</ColorButton>
			</div>
		</Modal>
	)
}

export default TokenModal
