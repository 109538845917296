import { NavTabs } from '@/components/NavTabs'
import { ICON_SIZES } from '@/constants/iconSizes'
import { styled, Typography } from '@mui/material'
import { XCircle } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'
import { usePostsControllerFindManyQuery, usePostsControllerGetGridMetaQuery, useToggleHidePostMutation } from '../api/feedApi'
import { AnalyticsFrame } from '../components/AnalyticsFrame'
import { CommonCard } from '../components/CommonCard'
import { DataTableWithRoleCheck } from '../components/dataTable/DataTable'
import { Layout } from '../components/Layout'
import { DashboardType } from '../types/dashboardTypes'
import { generateDynamicLabel } from '../utils/getTypeLabel'

const ToolTipExplainerBodyText = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(2),
}))

const FeedMainPage = () => {
	const [selectedRows, setSelectedRows] = useState<string[]>([])
	const [hidePostMutation, { isLoading }] = useToggleHidePostMutation()
	const handleHidePosts = useCallback(() => {
		hidePostMutation({
			ids: selectedRows,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRows, hidePostMutation, isLoading])

	const tableActions = useMemo(
		() => [
			{
				label: generateDynamicLabel({ subj: 'Hide Post', count: selectedRows.length }),
				icon: <XCircle size={ICON_SIZES.sm} weight="bold" />,
				onClick: handleHidePosts,
				isVisible: selectedRows.length > 0,
				helperText: (
					<div style={{ padding: '.64rem' }}>
						<Typography variant="h6">Moderate User Content</Typography>
						<ToolTipExplainerBodyText variant="body1">
							Hide hateful or harmful content from students. You can always bring it back by toggling the "Deleted" column again.&nbsp;
						</ToolTipExplainerBodyText>
					</div>
				),
			},
		],
		[handleHidePosts, selectedRows],
	)

	return (
		<Layout>
			<CommonCard
				title="Feed"
				subHeader="Welcome to the feed dashboard! Find information about posts and post activity. Click the “DETAILS” tab to view individual post information."
			>
				<NavTabs
					initialTab={0}
					withHistory
					tabs={[
						{
							label: 'Post Details',
							component: (
								<DataTableWithRoleCheck
									listQuery={usePostsControllerFindManyQuery}
									metaQuery={usePostsControllerGetGridMetaQuery}
									selectedRows={selectedRows}
									setSelectedRows={setSelectedRows}
									actions={tableActions}
								/>
							),
						},
						{
							label: 'Post Analytics',
							component: <AnalyticsFrame dashboardType={DashboardType.FEED} />,
						},
						{
							label: 'Comment Analytics',
							component: <AnalyticsFrame dashboardType={DashboardType.FEED_COMMENTS} />,
						},
					]}
				/>
			</CommonCard>
		</Layout>
	)
}

export default FeedMainPage
