import { ContentPreloader } from '@/components/ContentPreloader'
import { makeStyles } from '@material-ui/core'
import { RouteComponentProps } from 'react-router-dom'
import ErrorResponseCard from '../../components/ErrorResponseCard'
import { GroupChatIndividualInfo } from '../../components/groups/GroupIndividualInfo'
import { useGetGroupByIdQuery } from '../../api/groupsApi'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
}))
const GroupChatsIndividualPage = (props: RouteComponentProps<{ id: string }>) => {
	const classes = useStyles()
	const groupId = props.match.params.id
	const { data, isLoading, isError, error } = useGetGroupByIdQuery({ groupId })

	if (isError) {
		return 'status' in error ? <ErrorResponseCard error={error} /> : null
	}

	return (
		<div className={classes.root}>
			{isLoading ? (
				<ContentPreloader fullPage />
			) : (
				data && (
					<>
						<GroupChatIndividualInfo {...data} />
					</>
				)
			)}
		</div>
	)
}

export default GroupChatsIndividualPage
