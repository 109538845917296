const ROOT = 'volunteer' as const

const VOLUNTEERS = {
	ROOT,
	EVENTS: `${ROOT}/events`,
	ORGANIZATIONS: `${ROOT}/organizations`,
	MY: `${ROOT}/my`,
} as const

export default VOLUNTEERS
