import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ClockOutlinedIcon, GraduationHatOutlinedIcon, HouseOutlinedIcon, LocationOutlinedIcon } from '@/components'
import AboutCard from '@/components/IndividualPage/AboutCard'
import ContactCard from '@/components/IndividualPage/ContactCard'
import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import PageContainer from '@/components/IndividualPage/PageContainer'
import { ExpandableCard } from '@/components/IndividualPage/rsvp/ExpandableCard'
import ResponseButton from '@/components/IndividualPage/rsvp/ResponseButton'
import Title from '@/components/IndividualPage/Title'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import useResponseHandler from '@/features/responses/hooks/useResponseHandler'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { UserResponseStatuses } from '@/interfaces/common'
import { RootState } from '@/store'
import {
	getIsLoadingIndividualStudyAbroad,
	getSelectedStudyAbroad,
	loadStudyAbroadByIdRequest,
	selectCurrentUserHasResponse,
} from '@/store/studyAbroad'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppDispatch } from '@/store'

const IndividualStudyAbroad = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useAppDispatch()
	const isLoading = useSelector(getIsLoadingIndividualStudyAbroad)
	const studyAbroad = useSelector(getSelectedStudyAbroad)

	const currentUserHasResponse = useSelector((state: RootState) => selectCurrentUserHasResponse(state, UserResponseStatuses.interested))

	const {
		handleChangeResponse,
		handleReport,
		responseQuery: { isLoading: isLoadingResponse },
	} = useResponseHandler('studyAbroad', id)

	useWatchPageTitle({
		watchEntity: studyAbroad?.name,
	})

	useEffect(() => {
		dispatch(loadStudyAbroadByIdRequest(id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const studyAbroadName = `${studyAbroad?.name}`

	const locationsLabel =
		studyAbroad?.locations?.map(({ city, country, continent }: any) => `${city}, ${country} (${continent})`).join(', ') ?? ''
	const termsOfAvailabilityLabel = studyAbroad?.termsOfAvailability?.map(({ term }: any) => term).join(', ') ?? ''
	const housingTypeLabel = studyAbroad?.housingType?.map(({ housingType }: any) => housingType).join(', ') ?? ''
	const scholarshipsOfferedLabel = studyAbroad?.scholarships?.map(({ scholarshipName }: any) => scholarshipName).join(', ') ?? ''
	const sharingUrl = PATHS.APP.EDUCATION_ABROAD_SINGLE(id)

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.studyAbroad } }), [id])

	if (!studyAbroad?.id || isLoading) return <LoadingPlaceholder />

	return (
		<PageContainer>
			<ImageCard
				dotButton={{
					itemId: studyAbroad.id,
					reportSubject: ReportSubject.EducationAbroad,
					onSubmitReport: handleReport,
				}}
				photoUrl={studyAbroad.photoUrl}
			/>
			<Title
				type="Education Abroad"
				title={studyAbroadName}
				renderButtons={() => (
					<ResponseButton handleChange={handleChangeResponse} status={studyAbroad.responseStatus} loading={isLoadingResponse} />
				)}
				sharedData={sharedData}
				sharingUrl={sharingUrl}
			/>
			<ExpandableCard
				currentUserHasResponse={currentUserHasResponse}
				title={`See who's interested`}
				entityId={studyAbroad.id}
				entityType="studyAbroad"
			/>
			<AboutCard
				url={studyAbroad?.url}
				infoItems={[
					{
						label: locationsLabel,
						icon: <LocationOutlinedIcon />,
					},
					{
						label: termsOfAvailabilityLabel,
						icon: <ClockOutlinedIcon />,
					},
					{
						label: housingTypeLabel,
						icon: <HouseOutlinedIcon />,
					},
					{
						header: 'SCHOLARSHIP OFFERED',
						label: scholarshipsOfferedLabel,
						icon: <GraduationHatOutlinedIcon />,
					},
				]}
			/>
			<ContactCard name={studyAbroad.contactName} email={studyAbroad.contactEmail} subject={studyAbroad.name} />
		</PageContainer>
	)
}

export default IndividualStudyAbroad
