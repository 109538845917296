import { Dialog, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloseOutlined } from '@material-ui/icons'
import ImageLoader from './ImageLoader'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		position: 'relative',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	closeButton: {
		position: 'fixed',
		top: 0,
		right: 0,
		width: 40,
		height: 40,
		color: theme.palette.common.white,
		margin: 25,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
	},
}))

interface ImageViewModalProps {
	src: string
	isOpen: boolean
	alt?: string
	onClose: (e: any) => void
}

export const ImageViewModal = ({ src, isOpen, alt = '', onClose }: ImageViewModalProps) => {
	const classes = useStyles()

	const handleCloseModal = (e: any) => {
		e.stopPropagation()
		onClose(e)
	}

	return (
		<Dialog open={isOpen} onClose={handleCloseModal} className={classes.modal}>
			<div className={classes.container} onClick={handleCloseModal}>
				<IconButton className={classes.closeButton} onClick={handleCloseModal}>
					<CloseOutlined fontSize="medium" />
				</IconButton>
				<ImageLoader src={src} className={classes.image} alt={alt} />
			</div>
		</Dialog>
	)
}

export default ImageViewModal
