import { ColorButton, TextButton } from '@/components/Buttons'
import ScheduledPostsListModal from '@/components/Feeds/SchedulePost/ListModal'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { STYLES } from '@/constants'
import useOpenCreateModal from '@/features/posts/hooks/useOpenCreateModal'
import { useCurrentUser } from '@/hooks/userHooks'
import { useAppDispatch } from '@/store'
import { selectIsScheduledPostsModalOpened, setIsScheduledPostModalOpened } from '@/store/feeds'
import { Typography, makeStyles } from '@material-ui/core'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { FILTER_CONFIG, SCHEDULING_IS_ENABLED } from '../../../feed/config'
import { displayIfEnabled } from '../../../utils'
import FeedTabs from '../../filters/FeedTabs'
import { useHeaderRootStyles } from '../headerRootStyles'
import TokenModal from '@/components/Modal/TokenModal'
import { isDevEnv } from '@/utils/authHandlers'

const useStyles = makeStyles((theme) => ({
	blockHeader: {
		marginTop: 30,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0 1.5vw',
		justifyContent: 'space-between',
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		padding: '0 1.5vw',
		borderBottom: `solid 1.5px ${theme.palette.divider}`,
	},
	managePostsContainer: {
		display: 'flex',
	},
	startPost: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '2vh 1.5vw',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.neutral.light : theme.palette.action.hover,
		},
	},
	scheduledList: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '2vh 1.5vw',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.grey[100],
		},
	},
	profileImage: {
		width: 50,
		height: 50,
		minWidth: 50,
		minHeight: 50,
	},
	startPostText: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		marginLeft: 10,
		color: theme.palette.text.secondary,
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
}))

const ScheduledPostsButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<div>
			<TextButton
				onClick={(e) => {
					e.stopPropagation()
					onClick()
					return false
				}}
			>
				Scheduled Posts
			</TextButton>
		</div>
	)
}

const FeedTop = () => {
	const [showTokenModal, setShowTokenModal] = useState(false)
	const user = useCurrentUser()
	const classes = useStyles()
	const rootClasses = useHeaderRootStyles()
	const dispatch = useAppDispatch()

	// @TODO: remove from store get state from router by navigating to app/home/scheduled
	// check how we implemented RouterModal in the OMS
	const isScheduledPostsModalOpen = useSelector(selectIsScheduledPostsModalOpened)

	const toggleScheduledPostModal = useCallback(
		(open: boolean) => () => {
			dispatch(setIsScheduledPostModalOpened(open))
		},
		[dispatch],
	)

	const handleOpenCreateFeedModal = useOpenCreateModal()

	const isDev = isDevEnv()

	return (
		<>
			<TokenModal isOpen={showTokenModal} onClose={() => setShowTokenModal(false)} />
			<div className={rootClasses.root}>
				<div className={classes.blockHeader}>
					<Typography variant="h1">
						<b>Hello, {user.firstName}</b> 👋
					</Typography>
					{isDev && <ColorButton onClick={() => setShowTokenModal(!showTokenModal)}>Set up mobile</ColorButton>}
				</div>
				{displayIfEnabled(
					'usersAudience',
					<div className={classes.contentContainer}>
						<FeedTabs />
					</div>,
					FILTER_CONFIG,
				)}
				<div className={classes.managePostsContainer}>
					<div className={classes.startPost} tabIndex={0} onClick={handleOpenCreateFeedModal}>
						<LazyLoadProfileImage className={classes.profileImage} externalId={user.externalId} photoUrl={user.photoUrl} />
						<Typography className={classes.startPostText} component="span" variant="body1">
							Start a post
						</Typography>
					</div>
					{SCHEDULING_IS_ENABLED && (
						<div className={classes.scheduledList}>
							<ScheduledPostsButton onClick={toggleScheduledPostModal(true)} />
							<ScheduledPostsListModal isOpen={isScheduledPostsModalOpen} onClose={toggleScheduledPostModal(false)} />
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default FeedTop
