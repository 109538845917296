import { PATHS } from '@/constants'
import { NoMatch } from '@/pages'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import { autoSignInRequest, signInRequest } from '@/store/auth/actions'
import { getQueryParamByName } from '@/utils/urlHandlers'
import { push } from 'connected-react-router'
import { useLayoutEffect } from 'react'
import Router from './components/Router'
import useWatchPageTitle from './hooks/useWatchPageTitle'
import { getWebviewHelper } from './utils/webviewHelper'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
	const dispatch = useAppDispatch()
	const redirect = `${window.location.pathname}${window.location.search}`

	useLayoutEffect(() => {
		const code = getQueryParamByName('code')
		const appState = getQueryParamByName('state') || null
		const data = JSON.parse(appState) as { redirectPath?: string; schoolId?: number }

		const appCode = getQueryParamByName('appCode')
		const appMode = getQueryParamByName('appMode')
		const { isWebview } = getWebviewHelper()

		if (appMode && appCode) {
			const queryString = window.location.search
			dispatch(push(`${PATHS.LOGIN}${queryString}`))
		} else {
			if (code) {
				dispatch(signInRequest({ code, redirectPath: data?.redirectPath, schoolId: data?.schoolId })) // signIn after redirect from OAuth
				dispatch(push(PATHS.AUTH_REDIRECT))
			} else {
				if (!isWebview) {
					dispatch(autoSignInRequest(redirect))
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// @TODO: Replace this with Helmet https://www.npmjs.com/package/react-helmet-async
	useWatchPageTitle({
		isWatchingPathName: true,
	})

	return (
		<>
			<Router routes={ROUTES.root} noMatch={<NoMatch />} />
			<ToastContainer position="bottom-right" autoClose={5000} closeOnClick pauseOnHover />
		</>
	)
}

export default App
