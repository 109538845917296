const ROOT = 'organizations' as const

const ORGANIZATIONS = {
	ROOT,
	MY: `${ROOT}/my`,
	// @TODO: #uuid type conflict
	SINGLE: (id?: string | number) => `${ROOT}/organization/${id !== undefined ? id : ':id'}`,
} as const

export default ORGANIZATIONS
