import { ModalKind } from '@/components/Modal/types'
import { SCHOOL_ID } from '@/constants/configuration'
import { ICON_SIZES } from '@/constants/iconSizes'
import { mapEntityTypeToName } from '@/features/shareEntity/types/EntityTypes'
import { Plus, Question, XCircle } from '@phosphor-icons/react'
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { MutationDefinition } from '@reduxjs/toolkit/query'
import { useCallback, useMemo, useState } from 'react'
import AdminModal from '../../../../components/Modal/AdminModal'
import { LearnMoreProps } from '../../../../components/Modal/AdminModalContent'
import useAdminModal from '../../../../components/Modal/useAdminModal'
import { ActionButtonProps } from '../../types/dashboardTypes'
import { DataTableWithRoleCheck, TDataTableProps } from './DataTable'

export type SignupDashboardTableProps = TDataTableProps & {
	deleteMutation: UseMutation<MutationDefinition<any, any, any, any>>
	learnMoreProps?: LearnMoreProps
}

export const SignupDashboardTable = ({
	listQuery,
	metaQuery,
	entityScope,
	entityType,
	listQueryParams: queryParams,
	description,
	deleteMutation,
	learnMoreProps,
}: SignupDashboardTableProps) => {
	const modalConfig = useAdminModal({
		modalKinds: [ModalKind.ENTITY_SEARCH, ModalKind.LEARN_MORE],
		entitySearchProps: {
			entityType,
			entityScope,
			entityName: mapEntityTypeToName(entityType, entityScope),
		},
		learnMoreProps,
	})

	const [selectedRows, setSelectedRows] = useState<string[]>([])
	const [deleteSignupEntities] = deleteMutation()

	const handleDeleteEntities = useCallback(() => {
		deleteSignupEntities({
			entities: selectedRows,
			entityType,
			entityScope,
			schoolId: String(SCHOOL_ID),
		})
	}, [deleteSignupEntities, selectedRows, entityType, entityScope])

	const tableActions: ActionButtonProps[] = useMemo(
		() => [
			{
				label: 'Remove',
				icon: <XCircle size={ICON_SIZES.sm} weight="bold" />,
				onClick: handleDeleteEntities,
				isVisible: selectedRows.length > 0,
			},
			{
				label: 'Add',
				icon: <Plus size={ICON_SIZES.sm} weight="bold" />,
				onClick: () => {
					modalConfig.setModalState({ isOpen: true, modalKind: ModalKind.ENTITY_SEARCH })
				},
				isVisible: true,
			},
			{
				label: 'Learn More',
				icon: <Question size={ICON_SIZES.sm} weight="bold" />,
				onClick: () => modalConfig.setModalState({ isOpen: true, modalKind: ModalKind.LEARN_MORE }),
				isVisible: !!learnMoreProps?.description?.length,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedRows, learnMoreProps?.description, handleDeleteEntities, modalConfig.setModalState],
	)

	return (
		<>
			<DataTableWithRoleCheck
				listQuery={listQuery}
				metaQuery={metaQuery}
				listQueryParams={queryParams}
				actions={tableActions}
				entityType={entityType}
				entityScope={entityScope}
				selectedRows={selectedRows}
				setSelectedRows={setSelectedRows}
				description={description}
			/>
			<AdminModal {...modalConfig} />
		</>
	)
}
