import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ClockOutlinedIcon, LocationOutlinedIcon, PersonOutlinedIcon, SearchOutlinedIcon } from '@/components'
import AboutCard from '@/components/IndividualPage/AboutCard'
import ContactCard from '@/components/IndividualPage/ContactCard'
import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import PageContainer from '@/components/IndividualPage/PageContainer'
import Title from '@/components/IndividualPage/Title'
import { ExpandableCard } from '@/components/IndividualPage/rsvp/ExpandableCard'
import ResponseButton from '@/components/IndividualPage/rsvp/ResponseButton'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import useResponseHandler from '@/features/responses/hooks/useResponseHandler'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { UserResponseStatuses } from '@/interfaces/common'
import { RootState, useAppDispatch } from '@/store'
import {
	getIsLoadingIndividualResearch,
	getSelectedResearch,
	loadResearchByIdRequest,
	selectCurrentUserHasResponse,
} from '@/store/research'

const IndividualResearch = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useAppDispatch()

	const isLoading = useSelector(getIsLoadingIndividualResearch)
	const research = useSelector(getSelectedResearch)

	const currentUserHasResponse = useSelector((state: RootState) => selectCurrentUserHasResponse(state, UserResponseStatuses.interested))

	const {
		handleChangeResponse,
		handleReport,
		responseQuery: { isLoading: isLoadingResponse },
	} = useResponseHandler('research', id)

	useWatchPageTitle({
		watchEntity: research?.name,
	})

	useEffect(() => {
		dispatch(loadResearchByIdRequest(id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const researchName = `${research?.name}`

	const researchTermsLabel = research?.researchTerms?.map(({ researchTerm }: any) => researchTerm).join(', ') ?? ''
	const researchAreasLabel = research?.researchAreas?.map(({ researchArea }: any) => researchArea).join(', ') ?? ''
	const workSettingsLabel = research?.workSettings?.map(({ workSetting }: any) => workSetting).join(', ') ?? ''
	const positionTypesLabel = research?.positionTypes?.map(({ positionType }: any) => positionType).join(', ') ?? ''

	const sharingUrl = PATHS.APP.RESEARCH_SINGLE(id)

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.research } }), [id])

	if (!research?.id || isLoading) return <LoadingPlaceholder />

	return (
		<PageContainer>
			<ImageCard
				dotButton={{
					itemId: research.id,
					reportSubject: ReportSubject.Organization,
					onSubmitReport: handleReport,
				}}
				photoUrl={research.photoUrl}
			/>
			<Title
				type="research"
				title={researchName}
				renderButtons={() => (
					<ResponseButton handleChange={handleChangeResponse} status={research.responseStatus} loading={isLoadingResponse} />
				)}
				sharedData={sharedData}
				sharingUrl={sharingUrl}
			/>
			<ExpandableCard
				currentUserHasResponse={currentUserHasResponse}
				title={`See who's interested`}
				entityId={research.id}
				entityType="research"
			/>

			<AboutCard
				description={research?.description}
				url={research?.url}
				infoItems={[
					{
						label: researchAreasLabel,
						icon: <SearchOutlinedIcon />,
					},
					{
						label: researchTermsLabel,
						icon: <ClockOutlinedIcon />,
					},
					{
						label: workSettingsLabel,
						icon: <LocationOutlinedIcon />,
					},
					{
						label: positionTypesLabel,
						icon: <PersonOutlinedIcon />,
					},
				]}
			/>
			<ContactCard name={research.contactName} email={research.contactEmail} subject={research.name} />
		</PageContainer>
	)
}

export default IndividualResearch
