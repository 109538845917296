import settings from '@/constants/http'
import { formatDateNew } from '@/utils/dateTime2'
import filterUndefinedFields from '@/utils/filterUndefinedFields'
import { GridColDef, GridColumnVisibilityModel, GridPaginationModel, GridRowModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid'
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { MutationResolutionState, TDataTableProps } from '../dataTable/DataTable'
import { CELL_TEMPLATES, EDIT_CELL_TEMPLATES } from '../dataTable/templates/CellMap'
import VALUE_GETTERS_MAP from '../dataTable/valueGettersMap'

type UseDataTableParams = Pick<
	TDataTableProps,
	'entityType' | 'entityScope' | 'metaQuery' | 'metaQueryParams' | 'listQuery' | 'listQueryParams' | 'mutationDefinition'
> & {
	selectedRows?: string[]
	setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>
}

export const useDataTable = ({
	entityType,
	entityScope,
	metaQuery,
	metaQueryParams,
	listQuery,
	listQueryParams,
	mutationDefinition,
	selectedRows,
	setSelectedRows,
}: UseDataTableParams) => {
	const apiRef = useGridApiRef()
	const { data: meta, isLoading: isConfigLoading } = metaQuery(filterUndefinedFields({ entityType, entityScope, ...metaQueryParams }))

	const [mutation] = mutationDefinition?.() ?? [undefined]

	const [searchQuery, setSearchQuery] = useQueryParams({
		filters: withDefault(JsonParam, undefined),
		sortBy: withDefault(JsonParam, undefined),
		skip: withDefault(NumberParam, 0),
		take: withDefault(NumberParam, settings.DATA_GRID_PAGE_SIZE),
		fields: withDefault(JsonParam, {}),
		density: withDefault(StringParam, 'comfortable'),
		q: withDefault(StringParam, ''),
	})

	const clearFilters = useCallback(() => {
		apiRef.current.setFilterModel({ items: [] })
		setSearchQuery({ filters: undefined }, 'replaceIn')
	}, [apiRef, setSearchQuery])

	const clearSort = useCallback(() => setSearchQuery({ sortBy: undefined }, 'replaceIn'), [setSearchQuery])

	const selectData = useMemo(() => {
		const emptyData = { totalItems: 0, items: [] }
		return createSelector(
			(data) => data,
			(data: { totalItems: number; items: any[] } | undefined): { totalItems: number; items: any[] } => {
				return data ? data : emptyData
			},
		)
	}, [])

	const {
		data: rows,
		isLoading: areRowsLoading,
		refetch,
	} = listQuery(
		{
			skip: searchQuery.skip,
			take: searchQuery.take,
			sortBy: searchQuery.sortBy,
			filters: searchQuery.filters,
			...filterUndefinedFields({ entityType, entityScope, ...listQueryParams }),
		},
		{
			skip: isConfigLoading,
			selectFromResult: (result) => ({
				...result,
				data: selectData(result.data),
			}),
		},
	)

	const columns: GridColDef[] = useMemo(() => {
		return meta?.fields.map((field) => {
			const { template, valueGetterTemplate, ...column } = meta.dictionary[field]
			column['field'] = field
			if (!column['width']) {
				column['flex'] = 1
			}

			if (CELL_TEMPLATES[template]) {
				column['renderCell'] = CELL_TEMPLATES[template]
			}

			if (column.editable && EDIT_CELL_TEMPLATES[template]) {
				column['renderEditCell'] = EDIT_CELL_TEMPLATES[template]
				// column['renderEditCellProps'] = { myProp: true }
			}

			if (VALUE_GETTERS_MAP[valueGetterTemplate]) {
				column['valueGetter'] = VALUE_GETTERS_MAP[valueGetterTemplate]
			}
			return column
		})
	}, [meta])

	const paginationModel: GridPaginationModel = useMemo(
		() => ({
			page: searchQuery.skip / searchQuery.take,
			pageSize: searchQuery.take,
		}),
		[searchQuery.skip, searchQuery.take],
	)

	const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({})
	useEffect(() => {
		if (meta?.config?.visibleFields) {
			setColumnVisibilityModel(meta?.config?.visibleFields)
		}
	}, [meta])

	const handleColumnVisibilityModelChange = useCallback((newModel) => setColumnVisibilityModel(newModel), [])

	const handleRowSelectionModelChange = useCallback(
		(selected) => {
			setSelectedRows(selected)
		},
		[setSelectedRows],
	)

	const handleSortModelChanged = useCallback(
		(model: GridSortModel) => {
			setSearchQuery({ sortBy: model.length > 0 ? model : undefined }, 'pushIn')
		},
		[setSearchQuery, apiRef],
	)

	const handlePaginationModelChange = useCallback(
		(model: GridPaginationModel) => {
			setSearchQuery({ skip: model.page * model.pageSize, take: model.pageSize }, 'pushIn')
		},
		[setSearchQuery, apiRef],
	)

	const shouldUpdateRow = useCallback(
		({ oldRow, newRow }) => {
			let _new = structuredClone(newRow)
			let _old = structuredClone(oldRow)

			Object.entries(meta.dictionary).forEach(([key, { template }]) => {
				if (['date', 'dateTime'].includes(template)) {
					_old[key] = formatDateNew(oldRow[key], 'dateTimeShort')
					_new[key] = formatDateNew(newRow[key], 'dateTimeShort')
				}
			})

			return !_.isEqual(_old, _new)
		},
		[meta],
	)

	const handleRowUpdate = useCallback(
		async (newRow: MutationResolutionState['newRow'], oldRow: MutationResolutionState['oldRow']) =>
			new Promise<GridRowModel>(async (resolve, reject) => {
				if (!!mutation && shouldUpdateRow({ oldRow, newRow })) {
					const { isEditable, ...rowModification } = newRow
					await mutation(rowModification)

					resolve(newRow)
				} else {
					reject(oldRow)
				}
			}),
		[mutation, shouldUpdateRow, apiRef],
	)

	const rowSelectionProps = useMemo(() => {
		return {
			...(Array.isArray(selectedRows)
				? {
						checkboxSelection: true,
						disableRowSelectionOnClick: true,
						rowSelectionModel: selectedRows,
						onRowSelectionModelChange: handleRowSelectionModelChange,
				  }
				: {}),
		}
	}, [selectedRows, handleRowSelectionModelChange])

	return {
		apiRef,
		meta,
		columns,
		isConfigLoading,
		rows: rows.items,
		rowCount: rows.totalItems || 0,
		areRowsLoading,
		selectedRows,
		filters: searchQuery.filters,
		sortModel: searchQuery.sortBy,
		pageSizeOptions: [10, meta?.config?.pageSize ?? 20, 100],
		paginationModel,
		columnVisibilityModel,
		rowSelectionProps,
		handleSortModelChanged,
		handlePaginationModelChange,
		handleColumnVisibilityModelChange,
		handleRowUpdate,
		clearFilters,
		clearSort,
	}
}
