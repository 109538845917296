const ROOT = 'messages' as const

const MESSAGES = {
	ROOT,
	LIST: `${ROOT}/list`,
	USER_DIALOGUE: (id?: number) => `${ROOT}/user/${id !== undefined ? id : ':id'}`,
	GROUP: (id?: string) => `${ROOT}/group/${id !== undefined ? id : ':id'}`,
	DISCOVER_GROUP: `${ROOT}/discover`,
} as const

export default MESSAGES
