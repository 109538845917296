import { Button, Tooltip } from '@mui/material'
import { ComponentProps, ReactElement } from 'react'
import { ActionButtonProps } from '../../types/dashboardTypes'

const ToolBarButton: React.FC<ActionButtonProps & { index: number }> = ({ index, label, onClick, icon, isVisible, helperText }) => {
	const button = (
		<Button key={index} onClick={onClick} size="small" variant="outlined">
			{icon}
			&nbsp;{label}
		</Button>
	)

	if (helperText) {
		return <WithToolTip tooltipProps={{ title: helperText, enterDelay: 600, placement: 'left-end' }}>{button}</WithToolTip>
	}

	return button
}

export default ToolBarButton

export const WithToolTip: React.FC<
	Record<string, any> & { tooltipProps: Partial<ComponentProps<typeof Tooltip>>; children: ReactElement }
> = ({ tooltipProps: { placement = 'bottom', title, ...propsRest }, children }) => {
	if (title) {
		return (
			<Tooltip title={title} placement={placement} {...propsRest}>
				{children}
			</Tooltip>
		)
	}

	return <>{children}</>
}
