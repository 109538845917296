import { SCHOOL_ID } from '@/constants/configuration'
import { baseApi } from '../../../anthology/general/baseApi'
import EntityResultsParams from '../../../anthology/types/EntityResultsParams'

const API_BASE_URL = '/dashboard'
export const dashboardApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['Posts', 'Messages'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getPosts: builder.query<any, EntityResultsParams>({
				query: (params) => {
					const schoolId = SCHOOL_ID
					return {
						url: `${API_BASE_URL}/posts`,
						params: {
							...params,
							schoolId,
						},
						method: 'GET',
					}
				},
				providesTags: (result) => {
					if (!result) {
						return ['Posts']
					}
					return [...result.items.map(({ id }: any) => ({ type: 'Posts', id })), { type: 'Posts', id: 'LIST' }]
				},
			}),
			getMessages: builder.query<any, EntityResultsParams>({
				query: (params) => {
					const schoolId = SCHOOL_ID
					return {
						url: `${API_BASE_URL}/messages`,
						params: {
							...params,
							schoolId,
						},
						method: 'GET',
					}
				},
				providesTags: (result) => {
					if (!result) return ['Messages']
					return [...result.items.map(({ id }: any) => ({ type: 'Messages', id })), { type: 'Messages', id: 'LIST' }]
				},
			}),
			toggleHideMessage: builder.mutation({
				query: (messages) => ({
					url: `${API_BASE_URL}/messages`,
					body: { messages },
					method: 'PATCH',
				}),
				invalidatesTags: ['Messages'],
			}),
		}),
	})

export const { useGetPostsQuery, useGetMessagesQuery, useToggleHideMessageMutation } = dashboardApi
